import React from 'react';
import {Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';


const isAuth = () => {
  let today = new Date();
  today = moment(today, 'DD/MM/YYYY', true).format('DD/MM/YYYY');
  let lastLogin = sessionStorage.getItem('lastLogin')  
  /* se data logada for nula seta data atual */
  console.log('passando no isAuth')
  if (lastLogin === null){
    lastLogin = today
  }
    
  if ((sessionStorage.getItem('codUser') !== null )  && (lastLogin === today)){
    return true
  }
  else
  {
    return false;
  }
};


const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest} = props;

  //console.log('the componente: ' + JSON.stringify(props));
  return (
    
    <Route
      {...rest}
      render={matchProps => (
        isAuth()?(
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Layout>    
            <Component {...matchProps} />
            <Switch>
              <Route path="/" exact>              
                <Redirect
                  to={{
                    pathname: '/gerencial',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />
              </Route>
              <Route path="/financas" exact>              
                <Redirect
                  to={{
                    pathname: '/financas',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />
              </Route>

              <Route
                exact
                path="/front" 
              >              
                <Redirect
                  to={{
                    pathname: '/front',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />
              </Route>

            
              <Route path="/estoque" exact>              
                <Redirect
                  to={{
                    pathname: '/estoque',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />
              </Route>
              <Route path="/crm" exact>              
                <Redirect
                  to={{
                    pathname: '/crm',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />
              </Route>
              
              <Route path="/frotas" exact>              
                <Redirect
                  to={{
                    pathname: '/frotas',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />
              </Route>
     
              <Route path="/clientes" exact>              
                <Redirect
                  to={{
                    pathname: '/login',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />

              </Route>

              <Route path="/dashboard" exact>              
                <Redirect
                  to={{
                    pathname: '/login',                                  
                    state: { message: 'Usuário não autorizado' }
                  }}
                />
              </Route>              
            </Switch>              
          </Layout>
        )
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
