import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AMODULO_APPCLI, AMODULO_CRM, AMODULO_ESTOQUE, AMODULO_FINANCAS, AMODULO_GERENCIAL, AMODULO_RECEPTIVO } from 'consts';
const Logout = props => {
  const { history } = props;

  let modulo = sessionStorage.getItem('modulo');
  console.log('o módulo localizado na saída é: ' + modulo);

  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('codModulo');
  sessionStorage.removeItem('mesComboProd');
  sessionStorage.removeItem('anoComboProd');
  sessionStorage.removeItem('moeda');
  sessionStorage.removeItem('trabOutraMoeda');
  sessionStorage.removeItem('formaPDV');
  sessionStorage.removeItem('codEmpresa');
  sessionStorage.removeItem('mod-ger-tipoExtraFat');
  sessionStorage.removeItem('fantasia');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('codUser');
  sessionStorage.removeItem('modulo');
  sessionStorage.removeItem('localizador');
  sessionStorage.removeItem('codOS');
  sessionStorage.removeItem('exportaLayoutA2x');
  sessionStorage.removeItem('nomePax');
  sessionStorage.removeItem('exportaContabil');
  sessionStorage.removeItem('identEmpCtbPeloSetor');
  sessionStorage.removeItem('vendeHospedagem');
  sessionStorage.removeItem('vendeTransporte');
  sessionStorage.removeItem('vendeAereo');
  sessionStorage.removeItem('vendeOpcionais');
  sessionStorage.removeItem('controlaServicosComPulseira');
  sessionStorage.removeItem('codDialeto');
  sessionStorage.removeItem('codPais');
  sessionStorage.removeItem('controlaDisponibServico');
  sessionStorage.removeItem('exibeCustoPadraoCadServico');
  sessionStorage.removeItem('vincServaServTransp');
  sessionStorage.removeItem('controlaPontosServico');
  sessionStorage.removeItem('exibeNomeServNoFornecCadServ');
  sessionStorage.removeItem('vendeSeguroViagem');
  sessionStorage.removeItem('recebDiretoFornec');
  sessionStorage.removeItem('detalhaFormaPgtoFornec');
  sessionStorage.removeItem('contrServRecebFornec');
  sessionStorage.removeItem('exportSnowland');
  sessionStorage.removeItem('carroDeApoio');
  sessionStorage.removeItem('vendeLocadoraVeiculos');
  sessionStorage.removeItem('vendeServicosViagens');
  sessionStorage.removeItem('vendeCruzeiros');
  sessionStorage.removeItem('vendePacotesViagens');
  sessionStorage.removeItem('trabProgramacaoSugestivo');
  sessionStorage.removeItem('trabHsServDiaEspec');
  sessionStorage.removeItem('hsUnicApresOpcGuia');
  
  sessionStorage.removeItem('trabTipoPadraoServico');

  sessionStorage.removeItem('codPDV');

  /* elimina chave pdv */
  sessionStorage.removeItem('pdv');
  /* elimina chave empresa */
  sessionStorage.removeItem('empresa');
  /* elimina chave configuracoes */
  sessionStorage.removeItem('cfg');
  /* elimina chave configuracoes CRM */
  sessionStorage.removeItem('cfg_crm');


  console.log('o módulo é: ' + modulo);

  switch (parseInt(modulo)) {
    case AMODULO_RECEPTIVO:
      history.push('/front');
      break;
    case AMODULO_FINANCAS:
      history.push('/financas');
      break;

    case AMODULO_GERENCIAL:
      history.push('/gerencial');
      break;

    case AMODULO_ESTOQUE:
      history.push('/estoque');
      break;
    case AMODULO_CRM:
      history.push('/crm');
      break;
    case AMODULO_APPCLI:
      history.push('/appcli');
      break;

  }


  //history.push('/gerencial');

  return (
    null
  );
};

Logout.propTypes = {
  history: PropTypes.object
};

export default withRouter(Logout);
