import AppBar from '@material-ui/core/AppBar';
import api from '../../../services/api';
import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Box from '@material-ui/core/Box';
import {
  CardContent, FormLabel, FormGroup, TableCell,
  TableBody, TableHead, TableRow, Table, Input, Button,
  Paper, ButtonGroup, Checkbox
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ButtonA2X from '../../Components/Bottombar'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AVOO_CHEGADA, AVOO_PARTIDA, ANAO, ASIM } from '../../../consts';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import {
  AIDIOMA_PORTUGUES, DESC_IDIOMA_PORTUGUES, AIDIOMA_ESPANHOL, DESC_IDIOMA_ESPANHOL,
  AIDIOMA_INGLES, DESC_IDIOMA_INGLES, AIDIOMA_FRANCES, DESC_IDIOMA_FRANCES, AIDIOMA_CHINES,
  DESC_IDIOMA_CHINES, AIDIOMA_JAPONES, DESC_IDIOMA_JAPONES, AIDIOMA_RUSSO, DESC_IDIOMA_RUSSO,
  AIDIOMA_ALEMAO, DESC_IDIOMA_ALEMAO, AIDIOMA_BILINGUE, DESC_IDIOMA_BILINGUE, AIDIOMA_ITALIANO,
  DESC_IDIOMA_ITALIANO, AIDIOMA_COREANO, DESC_IDIOMA_COREANO, AIDIOMA_POLONES, DESC_IDIOMA_POLONES,
  AIDIOMA_GREGO, DESC_IDIOMA_GREGO, AIDIOMA_MANDARIM, DESC_IDIOMA_MANDARIM, AIDIOMA_CANTONES,
  DESC_IDIOMA_CANTONES, APAX_ADT, APAX_CHD, APAX_INF, APAX_SEN,
  ATIPO_EXTRA, ATIPO_FATURADO, ATIPO_TODOS, FORMA_DINHEIRO, FORMA_DOLAR, FORMA_EURO, FORMA_PESO,
  FORMA_PESO_CHILENO, FORMA_GUARANI, AREGIME_NENHUM, AREGIME_CM, AREGIME_MAP, AREGIME_FAP,
  AREGIME_ALL_INCLUSIVE
} from '../../../consts';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { formatCurrency, getDescFormaPgto, formatData } from '../../../lib/functions';
import ComboPadrao from 'views/Components/ComboPadrao';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import { diff } from 'deep-diff';
import { Modal } from '@material-ui/core';
import PaxForm from '../Pax/PaxForm';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal/DeleteConfirmationModal';
import { formatDateForInput } from 'lib/functions';
import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined';
import { loadConfigurations, getConfigField, loadCompany } from '../../../lib/configUtils';
import { CircularProgress } from '@material-ui/core';
import HistoricoObsOS from './HistoricoObsOS';
import { Info as InfoIcon } from '@material-ui/icons';
import { formatDateTimeISO, validateField } from '../../../lib/functions';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'
import PassengerScreen from 'views/Components/PassengerScreen';
import InputMask from 'react-input-mask';

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ padding: 8 * 3 }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


// Definição dos estilos
const styles = theme => ({
  textField: {
    marginTop: theme.spacing(0), // Margem superior
    marginBottom: "0.5rem", // Margem inferior
    marginRight: "0.5rem", // Margem direita
  },
  addButton: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    float: 'right', // alinhamento a direita
  },
  textarea: {
    width: '100%',
    height: '100px',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },

});

//class ScrollableTabsButtonAuto extends React.Component {
class OSForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      os: {
        titular: "", idioma: 1, regimehosp: AREGIME_NENHUM, mostraextrafat: ATIPO_TODOS
      },
      os_original: {},
      clientes: [],
      clientesfat: [],
      hoteis: [],
      voosIN: [],
      voosOUT: [],
      vendedores: [],
      eventos: [],
      paxos: [],
      canaisVenda: [],
      itensOS: [],
      empresa: {},
      configuracoes: { trabalhacomdeadline: "N", solicitantedaos: "N", trabalhacomevento: "N" },
      // deleção de pax
      isPaxDeleteModalOpen: false,
      selectedIdPax: 0,
      selectedNomePax: '',
      selectedPax: {},
      count: 0,
      activetab: 0,
      isNewRecord: false,
      isView: false,
      openAlert: false,
      totalPagar: 0,
      qtdeadtlcto: 0, //para lcto
      qtdechdlcto: 0,
      qtdeinflcto: 0,
      qtdesenlcto: 0,
      paises: [],
      tipodocumentospessoais: [],
      cargos: [],
      nacionalidades: [],
      showPassengerScreen: false,
      isLoadingCity: true,
      isLoadingCliente: false,
      translations: {}, // Armazenará as traduções
      errors: {},
      schema: {
        titular: {
          presence: { allowEmpty: false, message: 'Titular obrigatório' },
          length: { maximum: 100 },
          type: 'string'
        },
        codcliente: {
          presence: { allowEmpty: false, message: 'Cliente obrigatório' },
          length: { maximum: 10 },
          type: 'select-one'
        },
        codclientefaturado: {
          presence: { allowEmpty: false, message: 'Cliente Faturamento obrigatório' },
          length: { maximum: 10 },
          type: 'select-one'
        },
        codvendedor: {
          presence: { allowEmpty: false, message: 'Vendedor obrigatório' },
          length: { maximum: 10 },
          type: 'select-one'
        },
        solicitante: {
          presence: { allowEmpty: true, message: '' },
          length: { maximum: 50 },
          type: 'string'
        },
        fone: {
          presence: { allowEmpty: true, message: '' },
          length: { maximum: 20 },
          type: 'string'
        },
        apto: {
          presence: { allowEmpty: true, message: '' },
          length: { maximum: 20 },
          type: 'string'
        },
        email: {
          presence: { allowEmpty: true, message: '' },
          length: { maximum: 100 },
          type: 'string'
        }

      },
      /*
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor: theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        },
        textField: {
          marginTop: theme.spacing(2), // Mantém o espaçamento superior
          marginBottom:"0.5rem", 
          marginRight:"1rem"
          //marginTop:'2rem',
          //marginLeft: '1rem',
          //marginBottom: '1rem', // Adiciona o espaçamento inferior de 1 rem
    //      marginRight: '1rem', // Adiciona o espaçamento inferior de 1 rem
          //width: '100%', // Centraliza o campo dentro do container
        },
        
      })), */

    };
  }

  async componentDidMount() {
    this.setState({ isLoadingCliente: true });  // Inicia o carregamento
    this.performsTranslation();
    const { location } = this.props;
    const currentPath = location.pathname;

    const { id } = this.props.match.params;
    //console.log('the id: ' + id);
    if (id > 0) {
      const response = await api.get(`/os/${id}`);
      const osData = response.data[0];
      // formata dd/mm/yyyy
      osData.datain = formatDateForInput(osData.datain);
      osData.dataout = formatDateForInput(osData.dataout);
      osData.deadline = formatDateForInput(osData.deadline);
      osData.datasolicitacao = formatDateForInput(osData.datasolicitacao);
      const os_original = osData;
      const clientes = await api.get(`/cliente/listforchange/${response.data[0].codcliente}`);
      const clientesfat = await api.get(`/cliente/listforchange/${response.data[0].codcliente}`);

      this.setState({
        os: osData,
        os_original: os_original,
        clientes: clientes.data,
        clientesfat: clientesfat.data,
        activeTab: '1',
        isNewRecord: false,
        isView: (currentPath.includes('/view'))
      }
      );

      const [hoteisResponse, voosINResponse, voosOUTResponse, eventosResponse, canaisVendaResponse,
        vendedoresResponse, paxosResponse] = await Promise.all([
          api.get(`/hotel/listforchange/${response.data[0].codhotel_pousada}`),
          api.get(`/voo/listforchange/${response.data[0].codvooin}?tbvoo.chegadapartida=${AVOO_CHEGADA}`),
          api.get(`/voo/listforchange/${response.data[0].codvooout}?tbvoo.chegadapartida=${AVOO_PARTIDA}`),
          api.get(`/evento/listforchange/${response.data[0].codevento}`),
          api.get(`/canalvenda/listforchange/${response.data[0].codcanalvenda}`),
          api.get(`/vendedor/listforchange/${response.data[0].codvendedor}`),
          api.get(`/pax/os/${id}?sort=tbpaxgeral.seqpaxos`)
        ]);

      this.setState({
        activeTab: '1',
        isNewRecord: false,
        hoteis: hoteisResponse.data,
        voosIN: voosINResponse.data,
        voosOUT: voosOUTResponse.data,
        eventos: eventosResponse.data,
        canaisVenda: canaisVendaResponse.data,
        vendedores: vendedoresResponse.data,
        paxos: paxosResponse.data
      });

      this.loadCityPax();
      this.loadItensOS(osData.mostraextrafat, osData.codmoeda);
      this.setState({ isLoadingCliente: false });
    }
    else
      // se for novo registro
      if (id === 'novo') {
        this.setState({
          activeTab: '1',
          isNewRecord: true
        });
        this.state.isNewRecord = true;

        // se não trabalhar com multi-moeda então pegar a moeda as configurações
        // Atribuindo configuracoes.codmoeda a os.codmoeda como padrão em registro novo
        const exibecampopaispax = (getConfigField('codmoeda'));

        this.setState(prevState => ({
          os: {
            ...prevState.os,
            codmoeda: parseInt(getConfigField('codmoeda'), 10)  // Convertendo para inteiro, caso necessário
          }
        }));

        const clientes = await api.get(`/cliente/active/true`);
        this.setState({ clientes: clientes.data });

        const clientesfat = await api.get(`/cliente/active/true`);
        this.setState({ clientesfat: clientesfat.data });

        const hoteis = await api.get(`/hotel/active/true}`);
        this.setState({ hoteis: hoteis.data });

        const voosIN = await api.get(`/voo/active/true?tbvoo.chegadapartida=${AVOO_CHEGADA}`);
        this.setState({ voosIN: voosIN.data });

        const voosOUT = await api.get(`/voo/active/true?tbvoo.chegadapartida=${AVOO_PARTIDA}`);
        this.setState({ voosOUT: voosOUT.data });

        const vendedores = await api.get('/vendedor/active/true');
        this.setState({ vendedores: vendedores.data });

        const eventos = await api.get('/evento/active/true');
        this.setState({ eventos: eventos.data });

        const canaisVenda = await api.get('/canalvenda/active/true');
        this.setState({ canaisVenda: canaisVenda.data });
        //      
        this.setState({ idioma: AIDIOMA_PORTUGUES });
        this.setState({ isLoadingCliente: false });

      }
    // campos exibição grid pax
    const exibecampopaispax = (getConfigField('exibecampopaispax') == ASIM);
    const exibecampotipodocpessoal = (getConfigField('exibecampopaispax') == ASIM);
    const exibecampocargopax = (getConfigField('exibecampocargopax') == ASIM);
    const exibecamponacionalidepax = (getConfigField('exibecamponacionalidepax') == ASIM);


    const [paises, tipodocumentospessoais, cargos, nacionalidades] = await Promise.all([
      exibecampopaispax ? api.get(`/pais/active/true`) : Promise.resolve({ data: [] }),
      exibecampotipodocpessoal ? api.get(`/tipodocpessoal/active/true`) : Promise.resolve({ data: [] }),
      exibecampocargopax ? api.get(`/cargo/active/true`) : Promise.resolve({ data: [] }),
      exibecamponacionalidepax ? api.get(`/nacionalidade/active/true`) : Promise.resolve({ data: [] }),
    ]);

    this.setState({
      paises: paises.data,
      tipodocumentospessoais: tipodocumentospessoais.data,
      cargos: cargos.data,
      nacionalidades: nacionalidades.data
    });

  }

  handleChangeTab = (event, value) => {
    this.setState({ activetab: value });
    if (value === 1) {
      //this.loadProcedimentosOS();
    }
    else
      if (value === 2) {
        //      this.loadAnexos();
        //this.enabledProgressBarUpload();
        //      this.setState({showProgressBar: true});        
      }
      else
        if (value === 3) {
          //    this.loadFilesPreLiberacao();
          //this.enabledProgressBarUpload();
          //    this.setState({showProgressBarPreLiberacao: true});        
        }
  };

  /* radio button - mostra Extra Fat */
  handleOptionChange = (event) => {
    const { value } = event.target;
    console.log('o que está em event: ' + value);
    this.setState(prevState => ({
      os: {
        ...prevState.os,
        mostraextrafat: parseInt(value, 10)
      }
    }));
    //console.log('handleoption what is mostraextrafat: ' + this.state.os.mostraextrafat)
    this.loadItensOS(parseInt(value, 10), this.state.os.codmoeda);
  }


  /* Seleciona moeda */
  handleChangeCurrency = (codmoeda) => {
    this.setState(prevState => ({
      os: {
        ...prevState.os,
        codmoeda
      }
    }), () => {
      //console.log('handleCoin what is codmoeda: ' + this.state.os.codmoeda)
      this.loadItensOS(this.state.os.mostraextrafat, this.state.os.codmoeda); // O valor 1 é só um exemplo, ajuste conforme necessário
    });
  }

  /* usado para não exibir componente*/
  getNotDisplay(show) {
    if (show) {
      return 'none'
    }
    else
      return ''
  }

  /* total pagar */
  getTotalPagar() {
    let total = 0;
    const { itensOS } = this.state;
    {
      itensOS.slice(0, 100).map(item => (
        total += item.totalpagar))
    }
    return total;
  }

  /* insere nova OS */
  insereOS = async () => {
    const { os } = this.state;
    os['codusuario'] = parseInt(sessionStorage.getItem('codUser'), 10);
    os['codempresa'] = parseInt(sessionStorage.getItem('codEmpresa'), 10);
    os['situacao'] = 'A';
    const { paxos } = this.state;
    os['paxos'] = paxos;
    this.setState({ os: os });

    try {
      const response = await api.post(`/os`, this.state.os);
      if (response.status === 200) {
        //console.log('resposta da inserção: ' + JSON.stringify(response.data))
        //error.response.data.error
        //alert('OS inserida com sucesso! Número da OS: ' + response.data.nroos);
        //this.handleOpenAlert('OS inserida com sucesso! Número da OS: ' + response.data.nroos);  
        //this.props.history.push('/front/oss');

        this.handleOpenAlert(
          'OS inserida com sucesso! Número da OS: ' + response.data.nroos,
          () => this.props.history.push('/front/oss')  // função Callback para navegação depois do ok da mensagem
        );
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }


  /*  atualiza dados de OS - PUT */
  atualizaOS = async () => {
    const { os, os_original } = this.state;
    // Identificar as diferenças entre os objetos
    const differences = diff(os_original, os);

    console.log('o que está em diferenças: ' + JSON.stringify(differences));
    /* se não há diferenças entao apenas fecha*/
    if (!differences) {
      //console.log("Nenhuma alteração detectada.");
      this.props.history.push('/front/oss');
      return;
    }
    // Filtrar as diferenças para criar um novo objeto com as alterações
    const osAlterada = {};
    differences.forEach(d => {
      if (d.kind === 'E' || d.kind === 'N') { // 'E' para editado, 'N' para novo
        osAlterada[d.path.join('.')] = d.rhs; // d.path é o caminho da propriedade
      }
    });

    // Inclua campos obrigatórios, se necessário
    osAlterada['codusuario'] = parseInt(sessionStorage.getItem('codUser'), 10);

    //console.log('Campos alterados: ', osAlterada);

    try {
      console.log('o que está em osAlterada: ' + JSON.stringify(osAlterada));
      const response = await api.put(`/os/${os.codos}`, osAlterada);
      if (response.status === 200) {
        this.props.history.push('/front/oss');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.message);
      //      error.response.data.error
    }
  }



  /* Carrega Itens da OS */
  loadItensOS = async (opcaoextrafat, moeda) => {
    const { os } = this.state;
    let params = {};

    // Verifica a `opcao` e configura `params` conforme necessário
    if (opcaoextrafat === 1) {
      params['tbitensos.inclusopacote'] = 'N';
      params['tbitensos.codmoeda'] = moeda;
    } else if (opcaoextrafat === 2) {
      params['tbitensos.inclusopacote'] = 'S';
      params['tbitensos.codmoeda'] = moeda;
    } else {
      params['tbitensos.codmoeda'] = moeda;
    }

    // Adiciona o parâmetro de ordenação
    params['sort'] = 'tbitensos.dataservico';

    console.log('params.. ' + JSON.stringify(params));

    try {
      const response = await api.get(`/itemos/os/${os.codos}`, { params });
      console.log('itens da OS: ' + JSON.stringify(response.data));
      this.setState({ itensOS: response.data });
    } catch (error) {
      console.error('Erro ao carregar itens da OS:', error);
      // Adicione qualquer tratamento de erro aqui, se necessário
    }
  }


  formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  /* conta pax por tipo */
  contarPaxPorTipo(listaPax, tipoPax) {
    return listaPax.filter(pax => pax.tipopax === tipoPax).length;
  }

  formatData(data) {
    const dataServico = data;
    const dateObject = new Date(dataServico);
    const formattedDate = dateObject.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    return formattedDate;
  }

  formatHora(hora) {
    const horaApresentacao = hora;
    const dateObject = new Date(horaApresentacao);
    const formattedTime = dateObject.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return formattedTime;
  }

  formatDateTime = (isoString) => {
    const date = new Date(isoString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // `getUTCMonth()` retorna o mês de 0 a 11
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  /* evento on change componentes */
  /*OnChange = (event) => {
   const { os } = this.state;
   const { name, type, value, checked } = event.target;
   let adjustedValue = value; // Valor ajustado com base no tipo do input


   console.log('name-component:' + name + ' value:' + value + 'type:' + type);

   // Decide o tratamento com base no tipo do input ou nome
   switch (type) {
       case 'checkbox':
           // Usa o valor checked para campos do tipo checkbox
           // Tratamentos adicionais específicos para campos
           if (name === 'inativo') {
               if (checked === false) {
                   adjustedValue = ASIM;
               }
               else {
                   adjustedValue = ANAO;
               }
           }
           else {
               if (checked === false) {
                   adjustedValue = ANAO;
               } else {
                   adjustedValue = ASIM;
               }
           }
       case 'select-one':
         if (name === 'regimehosp') {
           adjustedValue = value;
         }
         else
           adjustedValue = Number(value);
           break;
       case 'textarea':
           break;
       default:
           if ((name == 'email')) {
             //console.log('entrou campo email..');
             adjustedValue = value.toLowerCase()
           } else {
             //console.log('entrou no uppercase');
               adjustedValue = value.toUpperCase();
           }
           break;
   }

   // Atualiza o estado com o valor ajustado
   console.log('vai atribuir em: ' + name + ' o valor: ' + adjustedValue);
  
   os[name] = adjustedValue;
   this.setState({ os: os });

}; */

  nonUpperCaseFields = ['email'];

  OnChange = (event) => {
    const { name, type, value, checked } = event.target;
    const { os, errors } = this.state;
    let adjustedValue = value; // Valor ajustado com base no tipo do input

    console.log('name: ' + name + ' type: ' + type + ' value: ' + value);

    switch (type) {
      case 'checkbox':
        adjustedValue = (name === 'inativo') ? (checked ? 'ANAO' : 'ASIM') : (checked ? 'ASIM' : 'ANAO');
        break;
      case 'select-one':
        adjustedValue = (name === 'regimehosp') ? value : Number(value);
        break;
      default:
        if (name === 'obs') {
          adjustedValue = value;
        }
        else
          adjustedValue = this.nonUpperCaseFields.includes(name) ? value.toLowerCase() : value.toUpperCase();
        break;
    }

    this.setState(prevState => ({
      os: {
        ...prevState.os, // Preserva os valores anteriores de 'os'
        [name]: adjustedValue
      },
      errors: {
        ...prevState.errors, // Preserva os valores anteriores de 'errors'
        [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
      }
    }));
  };


  /* Click salvar */
  onClickSave = () => {
    if (this.state.isNewRecord) {
      this.insereOS()
    }
    else {
      this.atualizaOS();
    }
  }


  /* Click cancelar */
  onClickCancel = () => {
    this.props.history.push('/front/oss');
  }

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message, callback) => {
    //this.setState({ openAlert: true });
    //this.setState({ messageAlert: message });

    this.setState({
      messageAlert: message,
      openAlert: true,
      onCloseCallback: callback
    });
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    //this.setState({ openAlert: false });
    this.setState({ openAlert: false });
    if (this.state.onCloseCallback) {
      this.state.onCloseCallback();
    }
  }


  /* Envio de dados */
  handleSubmit = () => {
    let errors = {};
    errors = this.validationRequiredFields();
    console.log('o que está em errors: ' + JSON.stringify(errors));
    this.setState({ errors });

    //console.log('o que está em errors ' + JSON.stringify(errors));
    //console.log(Object.keys(errors).length);
    if (Object.keys(errors).length === 0) {
      // Enviar os dados do formulário
      this.onClickSave();
    }
  };

  /* valida campos obrigatórios */
  validationRequiredFields = () => {
    const { os } = this.state;
    const { paxos } = this.state;
    const { schema } = this.state;
    let errors = {};

    /* valida Titular */
    const titularError = validateField('titular', os.titular, schema);
    if (titularError) {
      errors.titular = titularError;
    }
    /* valida Cliente  */
    const codClienteError = validateField('codcliente', os.codcliente, schema);
    if (codClienteError) {
      errors.codcliente = codClienteError;
    }

    /* valida Cliente Faturado */
    const codClienteFaturadoError = validateField('codclientefaturado', os.codclientefaturado, schema);
    if (codClienteFaturadoError) {
      errors.codclientefaturado = codClienteFaturadoError;
    }

    /* valida vendedor */
    const codVendedorError = validateField('codvendedor', os.codvendedor, schema);
    if (codVendedorError) {
      errors.codvendedor = codVendedorError;
    }

    /* validações Solicitante */
    const solicitanteError = validateField('solicitante', os.solicitante, schema);
    if (solicitanteError) {
      errors.solicitante = solicitanteError;
    }
    /* validações fone */
    const foneError = validateField('fone', os.fone, schema);
    if (foneError) {
      errors.fone = foneError;
    }
    /* validações apto */
    const aptoError = validateField('apto', os.apto, schema);
    if (aptoError) {
      errors.apto = aptoError;
    }
    /* validações email */
    const emailError = validateField('email', os.email, schema);
    if (emailError) {
      errors.email = emailError;
    }


    /* mensagem geral de campos obrigatórios */
    /* colocar campos obrigatórios com destaque em vermelho controle na tela  validar acima */
    if (!(Object.keys(errors).length === 0)) {
      //console.log('o tamanho do errors: ' + errors + ' lenght errors: ' + errors.length);
      //console.log('errors: ' + JSON.stringify(errors));
      this.handleOpenAlert('Verifique os campos obrigatórios!');
      return errors;
    }
    else
      /* valida obrigatoriedade pax na OS e exibe mensagem */
      if (paxos.length === 0) {
        let pax = {
          field: {
            presence: { allowEmpty: false, message: 'Informe pelo menos um pax' },
          }
        }
        errors.paxos = pax;
        this.handleOpenAlert(errors.paxos.field.presence.message);
        return errors;
      }


    //error = fieldSchema.presence.message;
    // Adicione validação para outros campos se necessário
    return errors;
  };

  /* Adiciona listaPax  */
  /* Adiciona listaPax  */
  addPax = () => {
    const { paxos, os } = this.state;
    const newPax = [];
    const totalPax = paxos.length;

    let qtdeadt = parseInt(this.state.qtdeadtlcto, 10) || 0;
    let qtdechd = parseInt(this.state.qtdechdlcto, 10) || 0;
    let qtdeinf = parseInt(this.state.qtdeinflcto, 10) || 0;
    let qtdesen = parseInt(this.state.qtdesenlcto, 10) || 0;

    // Array para mapear os diferentes tipos de passageiros
    const paxTypes = [
      { quantity: qtdeadt, tipopax: 1, desctipopax: 'ADT' },
      { quantity: qtdechd, tipopax: 2, desctipopax: 'CHD' },
      { quantity: qtdeinf, tipopax: 3, desctipopax: 'INF' },
      { quantity: qtdesen, tipopax: 5, desctipopax: 'SEN' },
    ];

    // Percorre cada tipo de passageiro
    paxTypes.forEach(({ quantity, tipopax, desctipopax }) => {
      for (let i = 0; i < quantity; i++) {

        let contapax = totalPax + newPax.length + 1;
        let nomepax = "";

        if (totalPax === 0 && newPax.length === 0) {
          nomepax = os.titular;
        } else {
          nomepax = `PAX${contapax}`;
        }
        //console.log('o contador é: ' + contapax);
        //console.log('o contador i: ' + i);

        newPax.push({
          idpax: contapax,
          codos: os.codos,
          nomepax: nomepax,
          tipopax: tipopax,
          desctipopax: desctipopax,
          categoria: 1,
          desccategoriapax: 'PAGANTE',
          codgrupo: 0,
          seqgrupo: 0,
          seqpaxos: contapax
        });
        //console.log('seqpaxos: ' + contapax);
        /*this.inserePax({
          codos : os.codos,
          nomepax: nomepax, 
          tipopax: tipopax,
          desctipopax: desctipopax,
          categoria: 1,
          desccategoriapax : 'PAGANTE',
          codgrupo: 0,
          seqgrupo: 0,
          seqpaxos: contapax}); */
      }
    });

    this.setState({ paxos: [...paxos, ...newPax] });
    this.setState({ qtdeadtlcto: 0, qtdechdlcto: 0, qtdeinflcto: 0, qtdesenlcto: 0 });

    /* insere novos pax  */
    //console.log('o que esetá em newpax: ' + JSON.stringify(newPax));
    if (!(this.state.isNewRecord)) {
      for (let i = 0; i <= newPax.length - 1; i++) {
        console.log(newPax[i]);
        this.inserePax(newPax[i])
      }
    }


  }

  OnChangeLctoPax = (event) => {
    const { name, value } = event.target;
    //console.log('acionando onchangelctopax: ' + name + '-' + value)
    // Atualiza o estado com a chave dinâmica
    this.setState({ [name]: value });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  // Método para atualizar a lista de pax
  updatePaxList = async (pax) => {
    const paxoff = pax;
    try {
      //const paxos = await api.get(`/pax/os/${this.state.os.codos}`);
      if (!(this.state.isNewRecord)) {
        const paxos = await api.get(`/pax/os/${this.state.os.codos}?sort=tbpaxgeral.seqpaxos`);
        if (paxos.status === 200) {
          this.setState({ paxos: paxos.data });
        }
      }
      else {
        //console.log('é alteração sem inserção -- off');
        const { paxos } = this.state; // lista de pax
        const index = paxos.findIndex(pax => pax.idpax === paxoff.idpax);

        if (index !== -1) {
          // Cria uma nova lista com o objeto substituído
          const novaListaPaxos = [
            ...paxos.slice(0, index),
            paxoff,
            ...paxos.slice(index + 1)
          ];

          // Atualiza o estado com a nova lista
          this.setState({ paxos: novaListaPaxos });
        } else {
          //console.log('Pax não encontrado na lista');
          const novoPax = paxoff; // Novo objeto pax a ser adicionado
          novoPax.seqpaxos = (this.state.paxos.length + 1);
          // Cria uma nova lista adicionando o novo pax ao final da lista existente
          const novaListaPaxos = [...this.state.paxos, novoPax];

          // Atualiza o estado com a nova lista de paxos
          this.setState({ paxos: novaListaPaxos });
        }
      }

      //console.log('vai carregar loadcitypax no updatePaxList')
      this.loadCityPax();

    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }


  /* fecha janela de confirmação exclusão de pax */
  openPaxDeleteModal = (idpax, nomepax) => {
    this.setState({ selectedIdPax: idpax });
    this.setState({ selectedNomePax: nomepax });
    this.setState({ isPaxDeleteModalOpen: true });
  };

  /* fecha janela de confirmação exclusão de pax */
  closePaxDeleteModal = () => {
    this.setState({ isPaxDeleteModalOpen: false });
  };

  /* remover pax da lista off e atualizar na sequencia */
  removePaxEReorganizarSequencia(idpax) {
    // Copia a lista atual de paxos do estado
    const { paxos } = this.state;

    // Localiza o índice do objeto na lista pelo idpax
    const index = paxos.findIndex(pax => pax.idpax === idpax);

    if (index !== -1) {
      // Remove o objeto localizado da lista
      paxos.splice(index, 1);

      // Reorganiza a sequência (seqpaxos) começando de 1
      const novaListaPaxos = paxos.map((pax, idx) => ({
        ...pax,
        seqpaxos: idx + 1
      }));

      // Atualiza o estado com a nova lista
      this.setState({ paxos: novaListaPaxos });
    } else {
      console.log('Pax não encontrado na lista');
    }
  }

  /* deleta pax */
  handleDeletePax = async (id) => {
    const { isNewRecord } = this.state;
    //console.log('vai excluir o id: ' + id)
    try {
      if (!(isNewRecord)) {
        //console.log('entrou para exclusão via api..')
        const response = await api.delete(`/pax/${id}`);
        // Verifica se a exclusão foi bem-sucedida
        if (response.status === 200) {
          try {
            this.closePaxDeleteModal(); // Aguarda a conclusão de closePaxDeleteModal
            this.updatePaxList(); // Executa updatePaxList após a conclusão de closePaxDeleteModal
          } catch (error) {
            console.error('Erro ao fechar o modal:', error);
          }
        }
      }
      else {
        console.log('exclusão de pax off');
        this.removePaxEReorganizarSequencia(id);
        this.closePaxDeleteModal();
      }

    } catch (error) {
      this.closePaxDeleteModal();
      this.handleOpenAlert(error.response.data.error);
      console.log(error);
      console.log(error.message);
    }
  };

  /* open form pax modal */
  openPaxModal = (id = 'novo') => {
    //console.log('passou o id pax: ' + id);
    let paxEncontrado = {};
    if (this.state.isNewRecord) {
      if (!(id === 'novo')) {
        const idpaxProcurado = parseInt(id, 10);
        paxEncontrado = this.state.paxos.find(pax => pax.idpax === idpaxProcurado);
      }
    }

    this.setState({
      open: true,
      selectedIdPax: id, // Define o ID do Pax que será editado, ou 'novo' para criar
      selectedPax: paxEncontrado
    });
  };

  /* insere novo pax*/
  inserePax = async (novopax) => {
    try {
      const response = await api.post(`/pax`, novopax);
      if (response.status === 200) {
        this.updatePaxList();
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  /* objetivo: buscar nome do pais na lista */
  getNomePais = (codpais) => {
    const { paises } = this.state;
    //console.log('acionou nome do pais ogetNomePais que está em paises; ' + JSON.stringify(paises));
    //  
    /*let id = codpais;
      if (id == null){
        id = 0;
      } */
    //console.log('vai buscar o código do pais: ' + codpais);
    //console.log('o que está em paises: ' + JSON.stringify(paises));
    const pais = paises.find(p => p.codpais === codpais);
    return pais ? pais.nomepais : '';
  }


  /* objetivo: buscar nome do cargo */
  getNomeCargo = (codcargo) => {
    const { cargos } = this.state;
    const cargo = cargos.find(c => c.codcargo === codcargo);
    return cargo ? cargo.cargo : '';
  }

  /* objetivo: buscar nome do pais na lista */
  getDescTipoDocPessoal = (codtipodocpessoal) => {
    const { tipodocumentospessoais } = this.state;
    const tiposdoc = tipodocumentospessoais.find(t => t.codtipodocpessoal === codtipodocpessoal);
    return tiposdoc ? tiposdoc.desctipodocpessoal : '';
  }


  loadCityPax = async () => {
    const { paxos } = this.state;
    const paxList = paxos; // Supõe que a lista de pax vem via prop
    console.log('passou no loadcity..')

    // Extraímos os códigos de cidade únicos das cidades
    const cityCodes = [...new Set(paxList.map(pax => pax.codcidade))];
    try {
      // Fazemos a requisição em lote para buscar dados cidades da lista de codigos cidades
      const paxCities = await this.fetchCity(cityCodes);
      console.log('carregou a lista de ciadades atualizadas..' + JSON.stringify(paxCities));
      // Atualizamos o estado com a lista de pax e o cache de nomes de cidades
      this.setState({ paxCities: paxCities, isLoadingCity: false });
    } catch (error) {
      console.error("Erro ao buscar cidades:", error);
      this.setState({ isLoadingCity: false });
    }
  };

  fetchCity = async (cityCodes) => {
    try {
      // Buscando cidades da lista de codcidade passada
      const response = await api.post('/cidade/findlist', {
        listid: cityCodes
      });

      // Atualiza o estado com a lista de cidades
      this.setState({ cities: response.data });

      const paxCities = response.data.reduce((acc, city) => {
        acc[city.codcidade] = city.desccidade;
        return acc;
      }, {});
      return paxCities;
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
  };

  /* objetivo: buscar a nacionalidade do pax  */
  getDescNacionalidadePax = (idnacionalidade) => {
    const { nacionalidades } = this.state;
    //console.log('idnacionalidade: ' + idnacionalidade);
    //console.log('nacionalidades: ' + JSON.stringify(nacionalidades));
    const nacionalidade = nacionalidades.find(n => n.idnacionalidade === idnacionalidade);
    return nacionalidade ? nacionalidade.descnacionalidade : '';
  }

  // Função para fechar o modal de histórico
  handleCloseHistorico = () => {
    this.setState({ openHistorico: false });
  };
  handleOpenHistorico = () => {
    this.setState({ openHistorico: true });
  };
  /* Realiza a tradução */
  async performsTranslation() {
    const termsNeeded = [CommonTranslations.BUTTON_SAVE,
    CommonTranslations.BUTTON_CANCEL,
    CommonTranslations.CHECKBOX_INATIVO,
    CommonTranslations.TABSHEET_GERAL];
    // Lista de termos da página
    const translations = await i18n.fetchTerms(termsNeeded);
    this.setState({ translations });
  }

  openPassengerScreen = () => {
    this.setState({
      showPassengerScreen: true,
      passengerName: 'John Doe', // Adapte conforme necessário
      companyLogo: 'http://186.10.27.237/img/general/logo.png'
      //companyLogo: 'https://turistur.tur.br/images/entity/entity-39/logo/logo-turistur.png'
    });
  };

  closePassengerScreen = () => {
    this.setState({ showPassengerScreen: false });
  };

  render() {
    //const useStyles = this.state.useStyles;
    const { classes } = this.props; // nome padrão onde vem estilo do materialui: classes precisa declarar 
    //const disablePaxTab = this.disablePaxTab();
    const { translations } = this.state;
    const { errors, isLoadingCliente } = this.state;
    const { openHistorico } = this.state;
    const { activetab } = this.state;
    const { os } = this.state;
    const { clientes } = this.state;
    const { clientesfat } = this.state;
    const { hoteis } = this.state;
    const { voosIN } = this.state;
    const { voosOUT } = this.state;
    const { vendedores } = this.state;
    const { eventos } = this.state;
    const { paxos } = this.state;
    const { canaisVenda } = this.state;
    const { itensOS } = this.state;
    const configuracoes = loadConfigurations('configuracoes');
    const empresa = loadCompany();
    // campos configuracoes:
    const exibecampodatanascpax = (getConfigField('exibecampodatanascpax') == ASIM);
    const exibecampoidadepax = (getConfigField('exibecampoidadepax') == ASIM);
    const exibecampopaispax = (getConfigField('exibecampopaispax') == ASIM);
    const exibecampotipodocpessoal = (getConfigField('exibecampotipodocpessoal') == ASIM);
    const exibecamporgpax = (getConfigField('exibecamporgpax') == ASIM);
    const exibecampofamiliapax = (getConfigField('exibecampofamiliapax') == ASIM);
    const exibecampocpfpax = (getConfigField('exibecampocpfpax') == ASIM);
    const exibecampofonepax = (getConfigField('exibecampofonepax') == ASIM);
    const exibecampocargopax = (getConfigField('exibecampocargopax') == ASIM);
    const exibecampocidadepax = (getConfigField('exibecampocidadepax') == ASIM);
    const exibecamposexopax = (getConfigField('exibecamposexopax') == ASIM);
    const exibecampoemailpax = (getConfigField('exibecampoemailpax') == ASIM);
    const exibecamponacionalidepax = (getConfigField('exibecamponacionalidepax') == ASIM);
    const exibeorgaoemissdocpax = (getConfigField('exibeorgaoemissdocpax') == ASIM);
    const trabalhareais = (getConfigField('trabalhareais') == ASIM);
    const trabalhadolar = (getConfigField('trabalhadolar') == ASIM);
    const trabalhaeuro = (getConfigField('trabalhaeuro') == ASIM);
    const trabalhapesos = (getConfigField('trabalhapesos') == ASIM);
    const trabalhapesoschile = (getConfigField('trabalhapesoschile') == ASIM);
    const trabalhaguarani = (getConfigField('trabalhaguarani') == ASIM);
    const temmascarapersonfone = (getConfigField('temmascarapersonfone') == ASIM);
    const mascarapersonfone = (getConfigField('mascarapersonfone'));
    console.log('trabalha reais: ' + trabalhareais)
    console.log('trabalha dolar: ' + trabalhadolar)
    console.log('trabalha pesos chile: ' + trabalhapesoschile)

    // cidades dos pax
    const { paxCities, isLoadingCity } = this.state;
    //{console.log('passando no render...')}

    return (
      <div>
        <AppBar
          color="default"
          position="static"
        >
          <Tabs
            indicatorColor="primary"
            onChange={this.handleChangeTab}
            scrollButtons="auto"
            scrollable
            textColor="primary"
            value={activetab}
          >
            <Tab
              icon={<AssignmentOutlinedIcon />}
              label={translations[CommonTranslations.TABSHEET_GERAL] || 'Geral'}

            />
            <Tab
              icon={<PeopleOutlineIcon />}
              label="Pax"
            // disabled={this.isErrorsEmpty} // Desabilita o Tab se errors não estiver vazio
            //disabled={disablePaxTab} // Desabilita o Tab se errors não estiver vazio

            //style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}              
            />
            <Tab
              icon={<LocalActivityOutlinedIcon />}
              id="tab3"
              label="Serviços"
            //style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}              
            />

          </Tabs>
        </AppBar>
        {/* Aba Geral */}
        {activetab === 0 &&
          <TabContainer>
            <CardContent
              style={{ display: this.getNotDisplay(this.state.isNewRecord) }}
            >

              <Typography
                variant="h3"
              >OS {os.nroos}
              </Typography>

              <Typography
                color="textSecondary"
                component="p"
                variant="body2"
              >


                {os.data && os.usuario && (
                  <p>Registrado em {formatDateTimeISO(os.data)} por {os.usuario}</p>
                )}
                {/* exibe orcamento quando OS gerado a partir de um orçamento */}
                {os.codorcamento > 0 && (
                  <Box display="flex" justifyContent="flex-end">
                    <Chip
                      label={`Orçamento: ${os.codorcamento}`}
                      disabled
                      variant="outlined"
                    />
                  </Box>
                )}

              </Typography>

            </CardContent>

            <Box width="100%">
              <TextField
                className={classes.textField}
                style={{ marginRight: "1rem" }}
                //error={this.hasErrorField('Nro. Reserva Cli [File]', os.nroreservaorigcli)}
                //error={!!errors.nroreservaorigcli}
                label="Nro. Reserva Cli [File]"
                name="nroreservaorigcli"
                onChange={this.OnChange}
                type="text"
                value={os.nroreservaorigcli || ''}
                variant="outlined"
                disabled={this.state.isView}

              />

              <TextField
                className={classes.textField}
                //style={{marginBottom:"0.5rem", marginRight:"1rem", width:'50%'}}
                style={{ width: '50%' }}
                label="Titular"
                name="titular"
                value={os.titular || ''}
                onChange={this.OnChange}
                error={!!errors.titular}
                helperText={errors.titular || ''}
                variant="outlined"
                disabled={this.state.isView}


              />

              {/* <TextField
                //className={useStyles.textField}
                style={{marginRight:"1rem", width:'50%'}}
                error={this.hasErrorField('titular', os.titular)}
                //error={this.validationRequiredFields}
                inputProps={{ maxLength: "50%" }} // tamanho máximo permitido
                helperText={os.titular == '' ? 'Titular obrigatório' : ' '}
                label="Titular"
                name="titular"
                onChange={this.OnChange}
                type="text"
                value={os.titular || ''}
                variant="outlined"
              /> */}

              {configuracoes && configuracoes.trabalhacomdeadline === 'N' ? null : (
                <TextField
                  className={classes.textField}
                  InputLabelProps={{ shrink: true, required: false }}
                  label="Deadline"
                  name="deadline"
                  onChange={this.OnChange}
                  type="date"
                  //value={os.deadline ? os.deadline.split('T')[0] : ''}
                  value={os.deadline}
                  variant="outlined"
                  disabled={this.state.isView}

                />
              )}
            </Box>
            <Box>
              <FormControl
                //  fullWidth
                className={classes.textField}
                variant="outlined"
                disabled={this.state.isView || isLoadingCliente}
                style={{ marginRight: "1rem" }}

              >
                <ComboPadrao
                  name={'codcliente'}
                  label={'Cliente'}
                  value={os.codcliente}
                  onChange={this.OnChange}
                  fieldId={'codcliente'}
                  listItems={clientes}
                  fieldDescription={'fantasia'}
                  helperText={errors.codcliente}

                />
              </FormControl>
              {/* Exibe o círculo de carregamento enquanto os dados estão sendo carregados */}
              {isLoadingCliente && <CircularProgress size={24} />}

              <FormControl
                //  fullWidth
                className={classes.textField}
                variant="outlined"
                disabled={this.state.isView || isLoadingCliente}

                style={{ marginRight: "1rem" }}
              >
                <ComboPadrao
                  name={'codclientefaturado'}
                  label={'Faturamento'}
                  value={os.codclientefaturado}
                  onChange={this.OnChange}
                  fieldId={'codcliente'}
                  listItems={clientesfat}
                  fieldDescription={'fantasia'}
                  helperText={errors.codclientefaturado}
                />
              </FormControl>
            </Box>

            <Box width="100%">
              <TextField
                InputLabelProps={{ shrink: true, required: false }}
                className={classes.textField}
                //classes.textField
                inputProps={{ maxLength: 50 }}
                label="Data IN"
                name="datain"
                onChange={this.OnChange}
                //style={{ marginBottom: '1.0rem' }}                  
                type="date"
                //value={os.datain ? os.datain.split('T')[0] : 'dd/mm/yyyy'} 
                value={os.datain}
                variant="outlined"
                disabled={this.state.isView}

              />



              <FormControl
                style={{ width: '20%' }}
                variant="outlined"
                disabled={this.state.isView}
                className={classes.textField}
              >
                <ComboPadrao
                  name={'codvooin'}
                  label={'Voo/Rodoviário IN'}
                  value={os.codvooin}
                  onChange={this.OnChange}
                  fieldId={'codvoo'}
                  listItems={voosIN}
                  fieldDescription={'descvoo'}
                />
              </FormControl>


              <TextField
                InputLabelProps={{ shrink: true, required: false }}
                inputProps={{ maxLength: 10 }}
                label="Hora IN"
                name="horain"
                onChange={this.OnChange}
                //style={{ width: '150px' }}
                style={{ marginLeft: 20 }}
                type="time"
                value={os.horain || ''}
                variant="standard"
                disabled={this.state.isView}
              />
            </Box>

            <Box width="100%">
              <TextField
                className={classes.textField}
                InputLabelProps={{ shrink: true, required: false }}
                inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                label="Data OUT"
                name="dataout"
                onChange={this.OnChange}
                //onBlur = {this.onInputBlur}                        
                //type="datetime-local"              
                //style={{marginLeft: 20}}            
                //type="date"              
                type="date"
                //value ={os.dataOUT || ''} 
                //value ={os.dataout || 'yyyy-mm-dd'}
                //value={os.dataout ? os.dataout.split('T')[0] : 'dd/mm/yyyy'}                                   
                value={os.dataout}
                variant="outlined"
                disabled={this.state.isView}

              />

              <FormControl
                style={{ width: '20%' }}
                className={classes.textField}
                variant="outlined"
                disabled={this.state.isView}
              >
                <ComboPadrao
                  name={'codvooout'}
                  label={'Voo/Rodoviário OUT'}
                  value={os.codvooout}
                  onChange={this.OnChange}
                  fieldId={'codvoo'}
                  listItems={voosOUT}
                  fieldDescription={'descvoo'}
                />
              </FormControl>

              <TextField
                InputLabelProps={{ shrink: true, required: false }}
                inputProps={{ maxLength: 10 }}
                label="Hora OUT"
                name="horaout"
                onChange={this.OnChange}
                //style={{ width: '150px' }}
                style={{ marginLeft: 20 }}
                type="time"
                value={os.horaout || ''}
                variant="standard"
                disabled={this.state.isView}
              />
            </Box>

            <Box width="100%">

              <FormControl
                variant="outlined"
                disabled={this.state.isView}
                className={classes.textField}
                style={{ width: "50%", minWidth: "30%" }}
              >
                <ComboPadrao
                  name={'codhotel_pousada'}
                  label={'Hotel / Pousada Principal'}
                  value={os.codhotel_pousada}
                  onChange={this.OnChange}
                  fieldId={'codcliente'}
                  listItems={hoteis}
                  fieldDescription={'fantasia'}
                />
              </FormControl>


              <TextField
                className={classes.textField}
                style={{ marginRight: "1rem", width: "20%" }}
                label="Apto"
                name="apto"
                onChange={this.OnChange}
                type="text"
                value={os.apto || ''}
                variant="outlined"
                error={!!errors.apto}
                helperText={errors.apto || ''}
                disabled={this.state.isView}

              />

            </Box>

            {temmascarapersonfone ? (
              mascarapersonfone ? (
                // Se `mascarapersonfone` tiver um valor, aplica a máscara contida nele
                <InputMask
                  mask={mascarapersonfone} // Usa a máscara personalizada
                  value={os.fone || ""}
                  onChange={this.OnChange}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      className={classes.textField}
                      label="Fone"
                      id="fone"
                      name="fone"
                      fullWidth
                      margin="normal"
                      type="text"
                      variant="outlined"
                      error={!!errors.fone}
                      helperText={errors.fone || ''}
                      style={{ marginRight: "1rem", width: "30%" }}
                    />
                  )}
                </InputMask>
              ) : (
                // Se `temmascarapersonfone` for `true`, mas `mascarapersonfone` estiver vazio, exibe sem máscara
                <TextField
                  className={classes.textField}
                  label="Fone"
                  id="fone"
                  name="fone"
                  value={os.fone || ""}
                  onChange={this.OnChange}
                  fullWidth
                  margin="normal"
                  type="text"
                  variant="outlined"
                  error={!!errors.fone}
                  helperText={errors.fone || ''}
                  style={{ marginRight: "1rem", width: "30%" }}
                />
              )
            ) : (
              // Se `temmascarapersonfone` for `false`, aplica a máscara padrão
              <InputMask
                mask="(99) 9999-9999" // Máscara padrão
                value={os.fone || ""}
                onChange={this.OnChange}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    className={classes.textField}
                    label="Fone"
                    id="fone"
                    name="fone"
                    fullWidth
                    margin="normal"
                    type="text"
                    variant="outlined"
                    error={!!errors.fone}
                    helperText={errors.fone || ''}
                    style={{ marginRight: "1rem", width: "30%" }}
                  />
                )}
              </InputMask>
            )}

            <TextField
              className={classes.textField}
              //error={this.hasErrorField('Email', os.email)}
              //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="E-mail"
              name="email"
              onChange={this.OnChange}
              style={{ width: '60%', marginRight: "1rem" }}
              type="text"
              value={os.email || ''}
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email || ''}
              disabled={this.state.isView}

            />

            <div>
              {configuracoes && configuracoes.trabalhacomevento === ANAO ? null : (
                <FormControl
                  className={classes.textField}
                  style={{ marginRight: '1rem' }}
                  variant="outlined"
                  disabled={this.state.isView}

                >
                  <ComboPadrao
                    name={'codevento'}
                    label={'Evento'}
                    value={os.codevento}
                    onChange={this.OnChange}
                    fieldId={'codevento'}
                    listItems={eventos}
                    fieldDescription={'descevento'}
                  />
                </FormControl>
              )}

              {empresa && empresa.modulocrm === ANAO ? null : (

                <FormControl
                  //  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  disabled={this.state.isView}
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  <ComboPadrao
                    name={'codcanalvenda'}
                    label={'Canal de Venda'}
                    value={os.codcanalvenda}
                    onChange={this.OnChange}
                    fieldId={'codcanal'}
                    listItems={canaisVenda}
                    fieldDescription={'desccanal'}
                  />
                </FormControl>
              )}

              <FormControl
                //  fullWidth
                variant="outlined"
                disabled={this.state.isView}
                className={classes.textField}
              >
                <ComboPadrao
                  name={'codvendedor'}
                  label={'Vendedor'}
                  value={os.codvendedor}
                  onChange={this.OnChange}
                  fieldId={'codvendedor'}
                  listItems={vendedores}
                  fieldDescription={'nomevendedor'}
                  helperText={errors.codvendedor}
                />
              </FormControl>

              {configuracoes && configuracoes.exibetipopensaonaos === ANAO ? null : (

                <FormControl
                  className={classes.textField}
                  style={{ marginRight: "1rem", width: "30%", maxWidth: "30%" }}
                  variant="filled"
                  disabled={this.state.isView}

                >
                  <InputLabel
                    htmlFor="outlined-age-native-simple"
                    shrink={true} // Isso evita a sobreposição
                    style={{ padding: '0 4px' }} // Estilo adicional para melhor visualização
                  >
                    Regime Hosp.
                  </InputLabel>

                  <Select
                    inputProps={{
                      id: 'outlined-age-native-simple',
                      name: 'regimehosp'
                    }}
                    label="Regime Hosp."
                    native
                    onChange={this.OnChange}
                    value={os.regimehosp || ''}
                  >

                    <option
                      key={AREGIME_NENHUM}
                      value={AREGIME_NENHUM}
                    >
                      {AREGIME_NENHUM}</option>

                    <option
                      key={AREGIME_CM}
                      value={AREGIME_CM}
                    >
                      {AREGIME_CM}</option>

                    <option
                      key={AREGIME_MAP}
                      value={AREGIME_MAP}
                    >
                      {AREGIME_MAP}</option>

                    <option
                      key={AREGIME_FAP}
                      value={AREGIME_FAP}
                    >
                      {AREGIME_FAP}</option>

                    <option
                      key={AREGIME_ALL_INCLUSIVE}
                      value={AREGIME_ALL_INCLUSIVE}
                    >
                      {AREGIME_ALL_INCLUSIVE}</option>

                  </Select>
                </FormControl>)}

            </div>

            {configuracoes && configuracoes.solicitantedaos === ANAO ? null : (

              <Box width="100%">
                <TextField
                  className={classes.textField}
                  //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                  label="Solicitante"
                  name="solicitante"
                  onChange={this.OnChange}
                  style={{
                    marginRight: "1rem",
                    //display: this.getNotDisplay(configuracoes.solicitantedaos === ANAO)
                  }}
                  type="text"
                  value={os.solicitante || ''}
                  variant="outlined"
                  error={!!errors.solicitante}
                  helperText={errors.solicitante || ''}
                  disabled={this.state.isView}

                />

                <TextField
                  InputLabelProps={{ shrink: true, required: false }}
                  inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                  label="Data Solicitação"
                  name="datasolicitacao"
                  onChange={this.OnChange}
                  //onBlur = {this.onInputBlur}                        
                  //type="datetime-local"              
                  //style={{marginLeft: 20}}            
                  //type="date"
                  //style={{marginRight:"1rem"}}          
                  style={{
                    marginRight: "1rem",
                    //display: this.getNotDisplay(configuracoes.solicitantedaos === ANAO)
                  }}
                  type="date"
                  //value ={os.datasolicitacao || 'dd/mm/yyyy'} 
                  //value={os.datasolicitacao ? os.datasolicitacao.split('T')[0] : 'dd/mm/yyyy'}
                  value={os.datasolicitacao}
                  variant="outlined"
                  disabled={this.state.isView}

                />

              </Box>
            )}

            <Typography
              color="textSecondary"
              component="p"
              variant="body2"

            >Observação Geral
            </Typography>
            <FormGroup className={classes.formGroup}>
              <textarea
                id="obs"
                name="obs"
                onChange={this.OnChange}
                className={classes.textarea}
                value={os.obs || ''}
                disabled={this.state.isView}

              />
              {!this.state.isNewRecord && ( // Verifica se isNewRecord é false
                <Button
                  //onClick={this.handleOpenDetalhes}
                  onClick={this.handleOpenHistorico}
                  //className={classes.iconButton}
                  size="small"
                  startIcon={<InfoIcon />}
                />
              )}
            </FormGroup>

          </TabContainer>}
        {/* Aba Pax */}
        {activetab === 1 &&
          <TabContainer>
            <div >
              <Box width="40%" >
                <FormControl
                  //error={this.hasErrorField('idioma')}
                  //fullWidth
                  ///width="10"
                  style={{ width: "40%", maxWidth: "1" }}
                  //style={{ marginTop: '1rem' }}    
                  //variant="filled" 
                  disabled={this.state.isView}

                >
                  <InputLabel
                    htmlFor="outlined-age-native-simple"
                    shrink={true} // Isso evita a sobreposição
                    style={{ padding: '0 4px' }} // Estilo adicional para melhor visualização
                    disabled={this.state.isView}

                  >
                    Idioma Padrão
                  </InputLabel>

                  <Select
                    inputProps={{
                      id: 'outlined-age-native-simple',
                      name: 'idioma'
                    }}
                    label="Idioma"
                    native
                    onChange={this.OnChange}
                    value={os.idioma || ''}
                  >

                    <option
                      key={AIDIOMA_PORTUGUES}
                      value={AIDIOMA_PORTUGUES}
                    >
                      {DESC_IDIOMA_PORTUGUES}</option>

                    <option
                      key={AIDIOMA_ESPANHOL}
                      value={AIDIOMA_ESPANHOL}
                    >
                      {DESC_IDIOMA_ESPANHOL}</option>

                    <option
                      key={AIDIOMA_INGLES}
                      value={AIDIOMA_INGLES}
                    >
                      {DESC_IDIOMA_INGLES}</option>

                    <option
                      key={AIDIOMA_FRANCES}
                      value={AIDIOMA_FRANCES}
                    >
                      {DESC_IDIOMA_FRANCES}</option>

                    <option
                      key={AIDIOMA_CHINES}
                      value={AIDIOMA_CHINES}
                    >
                      {DESC_IDIOMA_CHINES}</option>

                    <option
                      key={AIDIOMA_JAPONES}
                      value={AIDIOMA_JAPONES}
                    >
                      {DESC_IDIOMA_JAPONES}</option>

                    <option
                      key={AIDIOMA_RUSSO}
                      value={AIDIOMA_RUSSO}
                    >
                      {DESC_IDIOMA_RUSSO}</option>

                    <option
                      key={AIDIOMA_ALEMAO}
                      value={AIDIOMA_ALEMAO}
                    >
                      {DESC_IDIOMA_ALEMAO}</option>

                    <option
                      key={AIDIOMA_BILINGUE}
                      value={AIDIOMA_BILINGUE}
                    >
                      {DESC_IDIOMA_BILINGUE}</option>

                    <option
                      key={AIDIOMA_ITALIANO}
                      value={AIDIOMA_ITALIANO}
                    >
                      {DESC_IDIOMA_ITALIANO}</option>

                    <option
                      key={AIDIOMA_COREANO}
                      value={AIDIOMA_COREANO}
                    >
                      {DESC_IDIOMA_COREANO}</option>

                    <option
                      key={AIDIOMA_POLONES}
                      value={AIDIOMA_POLONES}
                    >
                      {DESC_IDIOMA_POLONES}</option>

                    <option
                      key={AIDIOMA_GREGO}
                      value={AIDIOMA_GREGO}
                    >
                      {DESC_IDIOMA_GREGO}</option>

                    <option
                      key={AIDIOMA_MANDARIM}
                      value={AIDIOMA_MANDARIM}
                    >
                      {DESC_IDIOMA_MANDARIM}</option>

                    <option
                      key={AIDIOMA_CANTONES}
                      value={AIDIOMA_CANTONES}
                    >
                      {DESC_IDIOMA_CANTONES}</option>

                  </Select>
                </FormControl>
                <Paper
                  style={{
                    maxWidth: 160,
                    width: '100%',
                    float: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '0px', // Ajuste o valor conforme necessário para a distância desejada
                    //maxWidth:'100'

                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
                    <InputLabel
                      htmlFor="qtdeADT"
                      shrink={true}
                      style={{ padding: '0 4px' }}
                    >
                      ADT
                    </InputLabel>
                    <Input
                      id="qtdeadtlcto"
                      name="qtdeadtlcto"
                      onChange={this.OnChangeLctoPax}
                      onBlur={this.onInputBlur}
                      placeholder="ADT"
                      style={{ width: 40 }}
                      type="number"
                      value={this.state.qtdeadtlcto}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
                    <InputLabel
                      htmlFor="qtdeCHD"
                      shrink={true}
                    //style={{ padding: '0 4px' }}
                    >
                      CHD
                    </InputLabel>
                    <Input
                      id="qtdechdlcto"
                      name="qtdechdlcto"
                      onChange={this.OnChangeLctoPax}
                      onBlur={this.onInputBlur}
                      placeholder="CHD"
                      style={{ width: 40 }}
                      type="number"
                      value={this.state.qtdechdlcto}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
                    <InputLabel
                      htmlFor="qtdeINF"
                      shrink={true}
                    //style={{ padding: '0 4px' }}
                    >
                      INF
                    </InputLabel>
                    <Input
                      id="qtdeinflcto"
                      name="qtdeinflcto"
                      onChange={this.OnChangeLctoPax}
                      //onBlur={this.onInputBlur}
                      placeholder="INF"
                      style={{ width: 40 }}
                      type="number"
                      value={this.state.qtdeinflcto}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
                    <InputLabel
                      htmlFor="qtdeSEN"
                      shrink={true}
                    //style={{ padding: '0 4px' }}
                    >
                      SEN
                    </InputLabel>
                    <Input
                      id="qtdesenlcto"
                      name="qtdesenlcto"
                      onChange={this.OnChangeLctoPax}
                      //onBlur={this.onInputBlur}
                      placeholder="SEN"
                      style={{ width: 40 }}
                      type="number"
                      value={this.state.qtdesenlcto}
                    />
                  </div>
                  <Button
                    color="primary"
                    id="inserePax"
                    onClick={() => this.addPax()}
                    style={{ alignSelf: 'flex-end' }}
                    disabled={this.state.isView}
                  >
                    <ArrowDownwardIcon />
                  </Button>
                  <div>
                    <Button
                      color="primary"
                      //style={classes.addButton}
                      className={classes.addButton}
                      variant="contained"
                      onClick={() => this.openPaxModal('novo')}
                      disabled={this.state.isView}

                    >
                      Novo
                    </Button>
                    {/*<Button
                      onClick={this.openPassengerScreen} // Abre o PassengerScreen ao clicar
                    >
                      Placa
                    </Button> *}
                    {/* Modal que renderiza o PassengerScreen */}
                    <Dialog
                      open={this.state.showPassengerScreen}
                      onClose={this.closePassengerScreen}
                      fullScreen // Ocupa a tela toda
                    >
                      <DialogContent>
                        <PassengerScreen
                          passengerName={this.state.paxos[0].nomepax}
                          companyLogo={this.state.companyLogo}
                        />
                        <Button onClick={this.closePassengerScreen} color="secondary">
                          Fechar
                        </Button>
                      </DialogContent>
                    </Dialog>
                    <Modal
                      open={this.state.open}
                      onClose={this.handleClose}
                    >
                      <div style={{ margin: '100px auto', width: '80%', padding: '20px', backgroundColor: 'white' }}>
                        <PaxForm
                          pax={this.state.selectedPax} // passa objetoPaxselecionado para o formulário.
                          onClose={this.handleClose}
                          onSave={(updatePax) => {
                            this.handleClose(); // Fechar o modal
                            this.updatePaxList(updatePax); // Atualizar a lista de pax
                          }}
                          isModal={true}
                          codOS={this.state.os.codos}
                          id={this.state.selectedIdPax}  // Passe o ID do Pax ou 'novo' para um novo registro
                        />
                      </div>
                    </Modal>
                  </div>



                </Paper>

              </Box>


              <Table className={''} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                      >
                        Seq.
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                      >
                        Nome
                      </Typography>
                    </TableCell>


                    <TableCell align="left">
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                      >
                        Tipo
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                      >
                        Categoria
                      </Typography>
                    </TableCell>
                    {exibecampodatanascpax && (

                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Data Nasc.
                        </Typography>
                      </TableCell>
                    )}
                    {exibecampoidadepax && (

                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Idade
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampopaispax && (

                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          País
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampotipodocpessoal && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Tipo Doc.
                        </Typography>
                      </TableCell>
                    )}
                    {exibecamporgpax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Documento
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampofamiliapax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Família
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampocpfpax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          CPF
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampofonepax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Fone
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampocargopax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Cargo
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampocidadepax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Cidade / UF
                        </Typography>
                      </TableCell>
                    )}


                    {exibecamposexopax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Sexo
                        </Typography>
                      </TableCell>
                    )}

                    {exibecampoemailpax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          E-mail
                        </Typography>
                      </TableCell>
                    )}

                    {exibecamponacionalidepax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Nacionalidade
                        </Typography>
                      </TableCell>
                    )}

                    {exibeorgaoemissdocpax && (
                      <TableCell align="left">
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Órgão Emissor Doc.
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell align="left">
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                      >
                        Ações
                      </Typography>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {paxos.map((pax) => (
                    <TableRow key={pax.seqpaxos}>
                      <TableCell align="left" width="5">{pax.seqpaxos}</TableCell>
                      <TableCell align="left" width="250">
                        <Typography
                          component="span"
                          variant="body1"
                          style={{ marginRight: '8px' }} // Espaçamento entre o nome e o localizador
                        >
                          {pax.nomepax}
                        </Typography>
                        {empresa && empresa.moduloadmappcli === ANAO ? null : (
                          <Typography
                            component="span"
                            variant="caption" // Define o tamanho da fonte como menor
                            color="textSecondary" // Cor mais discreta
                            style={{ fontStyle: 'italic' }} // Adiciona um estilo para diferenciar
                          >
                            ({pax.localizador}) {/* Exibe o localizador entre parênteses */}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell align="left" width="5">{pax.desctipopax}</TableCell>
                      <TableCell align="left" width="5">{pax.desccategoriapax}</TableCell>
                      {exibecampodatanascpax && (
                        <TableCell align="left" width="5" >{formatData(pax.datanasc)}</TableCell>
                      )}
                      {exibecampoidadepax && (
                        <TableCell align="left" width="5" >{pax.idade}</TableCell>
                      )}

                      {exibecampopaispax && (
                        <TableCell align="left" width="5" >{this.getNomePais(pax.codpais)}</TableCell>
                      )}

                      {exibecampotipodocpessoal && (
                        <TableCell align="left" width="5" >{this.getDescTipoDocPessoal(pax.codtipodocpessoal)}</TableCell>
                      )}

                      {exibecamporgpax && (
                        <TableCell align="left" width="5" >{pax.rg}</TableCell>
                      )}


                      {exibecampofamiliapax && (
                        <TableCell align="left" width="5" >{pax.codfamilia}</TableCell>
                      )}

                      {exibecampocpfpax && (
                        <TableCell align="left" width="5" >{pax.cpfpax}</TableCell>
                      )}

                      {exibecampofonepax && (
                        <TableCell align="left" width="5" >{pax.fone}</TableCell>
                      )}

                      {exibecampocargopax && (
                        <TableCell align="left" width="5" >{this.getNomeCargo(pax.codcargo)}</TableCell>
                      )}
                      {exibecampocidadepax && !isLoadingCity && (
                        <TableCell align="left" width="5">
                          {<td>{paxCities[pax.codcidade] || ''}</td>}
                        </TableCell>
                      )}

                      {exibecamposexopax && (
                        <TableCell align="left" width="5" >{pax.sexo}</TableCell>
                      )}

                      {exibecampoemailpax && (
                        <TableCell align="left" width="5" >{pax.email}</TableCell>
                      )}

                      {exibecamponacionalidepax && (
                        <TableCell align="left" width="5" >{this.getDescNacionalidadePax(pax.idnacionalidade)}</TableCell>
                      )}
                      {exibeorgaoemissdocpax && (
                        <TableCell align="left" width="5" >{pax.orgaoemissordoc}</TableCell>
                      )}


                      <TableCell width="1">
                        <Button
                          color="primary"
                          onClick={() => this.openPaxModal(pax.idpax)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          color="primary"
                          //onClick={() => this.setState({isPaxDeleteModalOpen: true})}                             
                          onClick={() => this.openPaxDeleteModal(pax.idpax, pax.nomepax)}
                        >
                          <DeleteOutlineIcon />
                        </Button>

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {/* totalizador de pax*/}

            <Table>
              <TableRow>
                <br />
              </TableRow>
            </Table>

            <Grid
              container spacing={1}>
              <Grid item xs={2} sm={1}>
                <Box
                  bgcolor="teal"
                  color="success.contrastText"
                  p={1}>
                  ADT {this.contarPaxPorTipo(paxos, APAX_ADT)}
                </Box>
              </Grid>
              <Grid item xs={2} sm={1}>
                <Box bgcolor="secondary.main" color="secondary.contrastText" p={1}>
                  CHD {this.contarPaxPorTipo(paxos, APAX_CHD)}
                </Box>
              </Grid>
              <Grid item xs={2} sm={1}>
                <Box bgcolor="warning.main" color="warning.contrastText" p={1}>
                  INF {this.contarPaxPorTipo(paxos, APAX_INF)}
                </Box>
              </Grid>

              <Grid item xs={2} sm={1}>
                <Box bgcolor="success.main" color="success.contrastText" p={1}>
                  SEN {this.contarPaxPorTipo(paxos, APAX_SEN)}
                </Box>
              </Grid>

              <Grid item xs={2} sm={1}>
                <Box align={'center'} bgcolor="text.disabled" color="background.paper" p={1}>
                  Total {this.contarPaxPorTipo(paxos, APAX_ADT) +
                    this.contarPaxPorTipo(paxos, APAX_CHD) +
                    this.contarPaxPorTipo(paxos, APAX_INF) +
                    this.contarPaxPorTipo(paxos, APAX_SEN)}
                </Box>
              </Grid>

            </Grid>


          </TabContainer>}

        {/* Aba Serviços */}
        {activetab === 2 &&
          <TabContainer>
            <FormControl component="fieldset">
              <FormLabel component="legend">Exibir</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="mostraextrafat"
                onChange={this.handleOptionChange}
                value={os.mostraextrafat}  // O valor atual que controla o RadioGroup
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Extras"
                  labelPlacement="top"
                  value={ATIPO_EXTRA}  // O valor que identifica esta opção
                />

                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Faturados"
                  labelPlacement="top"
                  value={ATIPO_FATURADO}  // O valor que identifica esta opção
                />

                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Todos"
                  labelPlacement="top"
                  value={ATIPO_TODOS}  // O valor que identifica esta opção
                />
              </RadioGroup>
            </FormControl>

            <div>
              <ButtonGroup >
                {trabalhareais && (
                  <Button
                    color="primary"
                    id="buttonLogado"
                    onClick={() => this.handleChangeCurrency(FORMA_DINHEIRO)} // BRL
                    disabled={this.state.os.codmoeda !== null && this.state.os.codmoeda == FORMA_DINHEIRO}
                  >
                    BRL
                  </Button>
                )}

                {trabalhadolar && (
                  <Button
                    color="primary"
                    id="buttonTodos"
                    onClick={() => this.handleChangeCurrency(FORMA_DOLAR)} // USD
                    disabled={this.state.os.codmoeda !== null && this.state.os.codmoeda == FORMA_DOLAR}
                  >
                    USD
                  </Button>
                )}

                {trabalhaeuro && (
                  <Button
                    color="primary"
                    id="buttonTodos"
                    onClick={() => this.handleChangeCurrency(FORMA_EURO)} // EUR
                    disabled={this.state.os.codmoeda !== null && this.state.os.codmoeda == FORMA_EURO}
                  >
                    EUR
                  </Button>
                )}

                {trabalhapesos && (
                  <Button
                    color="primary"
                    id="buttonTodos"
                    onClick={() => this.handleChangeCurrency(FORMA_PESO)} // ARS
                    disabled={this.state.os.codmoeda !== null && this.state.os.codmoeda == FORMA_PESO}
                  >
                    ARS
                  </Button>
                )}

                {trabalhaguarani && (
                  <Button
                    color="primary"
                    id="buttonTodos"
                    onClick={() => this.handleChangeCurrency(FORMA_GUARANI)} // PYG
                    disabled={this.state.os.codmoeda !== null && this.state.os.codmoeda == FORMA_GUARANI}
                  >
                    PYG
                  </Button>
                )}

                {(trabalhapesoschile) && (
                  <Button
                    color="primary"
                    id="buttonTodos"
                    onClick={() => this.handleChangeCurrency(FORMA_PESO_CHILENO)} // CLP
                    disabled={this.state.os.codmoeda !== null && this.state.os.codmoeda == FORMA_PESO_CHILENO}
                    variant={this.state.os.codmoeda === FORMA_PESO_CHILENO ? "contained" : "outlined"}
                  >
                    CLP
                  </Button>
                )}
              </ButtonGroup>
            </div>

            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: '1px',        // Define uma largura específica para a célula
                          minWidth: '1px',     // Garante que a célula não fique menor que 50px
                        }}
                      >

                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Item
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          width: '1px',        // Define uma largura específica para a célula
                          minWidth: '1px',     // Garante que a célula não fique menor que 50px
                        }}
                      >
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Fat
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Data
                        </Typography>
                      </TableCell>

                      <TableCell
                        style={{
                          width: '1px',        // Define uma largura específica para a célula
                          minWidth: '1px',     // Garante que a célula não fique menor que 50px
                        }}

                      >
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Hora
                        </Typography>
                      </TableCell>

                      <TableCell
                        style={{
                          width: '200px',        // Define uma largura específica para a célula
                          minWidth: '200px',     // Garante que a célula não fique menor que 50px
                        }}
                      >
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Serviço
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          ADT
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          CHD
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          INF
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          SEN
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Free
                        </Typography>
                      </TableCell>


                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Forma Pgto.
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                        >
                          Pg.
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body2"
                          align="right"
                        >
                          Total Pagar
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itensOS.slice(0, 100).map(item => (

                      <TableRow
                        hover
                        key={item.coditemos}
                      >
                        <TableCell
                          width="3"
                          style={{
                            backgroundColor: item.escalado === ASIM ? '#8a9298' : 'inherit',  // Cinza mais escuro
                            color: item.escalado === ASIM ? 'white' : 'inherit',               // Fonte branca
                            border: item.tiposervico === 2 ? '2px solid red' : 'none'        // Contorno vermelho
                          }}
                        >

                          {item.coditemos}
                        </TableCell>

                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            disabled={true}
                            checked={item.inclusopacote == ASIM}
                          />
                        </TableCell>

                        <TableCell width="5">{this.formatData(item.dataservico)} </TableCell>
                        <TableCell width="5">{this.formatHora(item.horainservico)}</TableCell>
                        <TableCell
                          width="5"

                          style={{
                            backgroundColor: item.cancelado === 'S'
                              ? '#FF6666'
                              : item.pago === 'S'
                                ? '#66B266'
                                : 'inherit',  // Fundo vermelho se cancelado, verde se pago
                            color: (item.cancelado === 'S' || item.pago === 'S')
                              ? 'white'
                              : 'inherit'   // Fonte branca se cancelado ou pago
                          }}
                        >
                          {item.descservico}
                        </TableCell>

                        <TableCell width="2">{item.qtdepax}</TableCell>
                        <TableCell width="2">{item.qtdepaxchd}</TableCell>
                        <TableCell width="2">{item.qtdepaxinf}</TableCell>
                        <TableCell width="2">{item.qtdepaxsen}</TableCell>
                        <TableCell width="2">{item.qtdepaxfree}</TableCell>
                        <TableCell width="5">
                          {item.pago === ASIM ? (
                            <Tooltip
                              title={`Pgo. Em: ${this.formatData(item.datapgto)}  
                                          fechamento: ${item.codfechamento}
                                          caixa: ${item.codcaixaitem}
                                        `}
                              arrow>
                              <span>{getDescFormaPgto(item.formapgto)}</span>
                            </Tooltip>
                          ) : ''}
                        </TableCell>


                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            disabled={true}
                            checked={item.pago == ASIM}
                          />
                        </TableCell>
                        <TableCell width="1" align="right">{formatCurrency(item.totalpagar)}</TableCell>

                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>

            <Grid align="right">
              <Grid align="right" item xs={12} sm={2}>

                <Box bgcolor="secondary.main"
                  color="background.paper" p={2}
                  style={{ display: 'none' }}
                >
                  Sub-Total: {1000.00}
                </Box>
              </Grid>
            </Grid>

            <Grid align="right">
              <Grid align="right" item xs={12} sm={2}>
                <Box bgcolor="text.disabled" color="background.paper" p={2}
                  style={{ display: 'none' }}
                >
                  Descontos(-): {0.00}
                </Box>
              </Grid>
            </Grid>


            <Grid align="right">
              <Grid align="right" item xs={12} sm={2}>
                <Box bgcolor="secondary.main" color="background.paper" p={2}
                  style={{ display: 'none' }}
                >
                  Total: {0.00}
                </Box>
              </Grid>
            </Grid>


            <Grid align="right">
              <Grid align="right" item xs={12} sm={2}>
                <Box bgcolor="success.main" color="background.paper" p={2}
                  style={{ display: 'none' }}>
                  Credito: {1000.00}
                </Box>
              </Grid>
            </Grid>


            <Grid align="right">
              <Grid align="right" item xs={12} sm={2}>
                <Box bgcolor="warning.main" color="background.paper" p={2}>
                  Total a Pagar: {formatCurrency(this.getTotalPagar())}

                </Box>
              </Grid>
            </Grid>

          </TabContainer>}

        <div>
          <ButtonA2X
            onClick={() => { this.handleSubmit() }}
            disabled={this.state.isView}

          >
            Salvar
          </ButtonA2X>

          <ButtonA2X
            //disabled={this.validationRequiredFields()}
            //style ={{ display: 'none'}}
            variant="contained"
            color="secondary"
            onClick={() => { this.onClickCancel() }}
          >
            Cancelar
          </ButtonA2X>


        </div>

        <div>
          <AlertInformation
            open={this.state.openAlert}
            handleClose={() => this.handleCloseAlert()}
            message={this.state.messageAlert}
          />

        </div>
        {/* Exclusão de Pax*/}
        <DeleteConfirmationModal
          open={this.state.isPaxDeleteModalOpen}
          handleClose={() => this.closePaxDeleteModal()}
          itemId={this.state.selectedIdPax}
          message={"Confirma exclusão do pax " + this.state.selectedNomePax + '?'}
          handleDelete={() => this.handleDeletePax(this.state.selectedIdPax)}
        />

        <div>
          {/* Modal para exibir o histórico da observação da OS */}
          <Dialog open={openHistorico} onClose={this.handleCloseHistorico} maxWidth="md" fullWidth>
            <DialogTitle className={classes.dialogTitle}>Histórico observação da OS</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <HistoricoObsOS osId={os.codos} />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseHistorico} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </div>


      </div>


    );
  }
}

OSForm.propTypes =
{
  classes: PropTypes.object.isRequired
};
//export default OSForm;
export default withStyles(styles)(OSForm);