import React, { Component } from 'react';
import { TextField, Button, Box, Dialog, DialogContent, FormControlLabel, Checkbox } from '@material-ui/core';
import { ANAO, APAX_ADT, APAX_CHD, APAX_INF, APAX_SEN } from 'consts';
import {
    ACATEGORIA_PAX_PAGANTE, ACATEGORIA_PAX_CORTESIA, ACATEGORIA_PAX_MEIAENTRADA, ASEXO_FEMININO, ASEXO_MASCULINO,
    ASIM, tPais
} from 'consts';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../services/api';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { diff } from 'deep-diff';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import { formatDateForInput, validarCPF, validateField } from 'lib/functions';
import ComboPadrao from 'views/Components/ComboPadrao';
import FormControl from '@material-ui/core/FormControl';
import ComboOnDemand from 'views/Components/ComboOnDemand';  // Importe o componente ComboOnDemand
import SearchCidade from 'views/Components/SearchCidade'; // Importa o componente SearchCidade
import InputMask from 'react-input-mask';
import { getConfigField, loadCompany } from '../../../lib/configUtils';
import { unescape } from 'lodash';

// Definição dos estilos
const styles = theme => ({
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
        variant: "outlined"
    },
    addButton: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        float: 'right',
    },
    modalContent: {
        maxHeight: '100vh', // Ajuste a altura máxima conforme necessário
        overflowY: 'auto', // Permite rolar verticalmente
        width: '50vh'
    }
});

class PaxForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //configurations: null,
            //isLoadingConfig: true,

            pax: {
                nomepax: '',
                tipopax: 1,
                categoria: 1,
                codos: this.props.codOS,
                datanasc: '',
                seqpaxos: 0,
                codcidade: 0,
                sexo: 'M'
            },
            paises: [],
            tiposdocpessoal: [],
            cargos: [],
            nacionalidades: [],
            pax_original: {},
            selectedCity: null,
            //selectedCity: {undefined}, // Armazena a cidade selecionada
            //            selectedCity:{codcidade: 0, desccidade: 'Cidade não definida', inativo: 'N', sigla: ''},
            errors: {},
            cpfError: false,
            schema: {
                nomepax: {
                    presence: { allowEmpty: false, message: 'Nome obrigatório' },
                    length: { maximum: 100 },
                    type: 'string'
                },
                fone: {
                    presence: { allowEmpty: true, message: '' },
                    length: { maximum: 20 },
                    type: 'string'
                },
                rg: {
                    presence: { allowEmpty: true, message: '' },
                    length: { maximum: 20 },
                    type: 'string'
                },
                idade: {
                    presence: { allowEmpty: true, message: '' },
                    length: { maximum: 10 },
                    type: 'integer'
                },
                codfamilia: {
                    presence: { allowEmpty: true, message: '' },
                    length: { maximum: 10 },
                    type: 'integer'
                },
                cpfpax: {
                    presence: { allowEmpty: true, message: '' },
                    length: { maximum: 50 },
                    type: 'string'
                },
                email: {
                    presence: { allowEmpty: true, message: '' },
                    length: { maximum: 100 },
                    type: 'string'
                },
                orgaoemissordoc: {
                    presence: { allowEmpty: true, message: '' },
                    length: { maximum: 20 },
                    type: 'string'
                }
            },
            isNewRecord: false,
            openAlert: false,
            messageAlert: '',
        };
    }

    async componentDidMount() {
        let id = this.props.id;
        let paxData;
        //this.loadConfigs();

        if (id > 0) {
            if (this.props.codOS === undefined) {
                paxData = this.props.pax;
                paxData.datanasc = '';
                console.log('codOS é undefined')
            } else {
                const response = await api.get(`/pax/${id}`);
                paxData = response.data[0];
                paxData.datanasc = formatDateForInput(paxData.datanasc);
                console.log('codOS é definido')
            }

            this.setState({
                pax: paxData,
                //pax_original: paxData,
                pax_original: JSON.parse(JSON.stringify(paxData)), // aplicar clonagem profunda. Se um campo no caso codcidade não
                isNewRecord: false                                 // atribuía em pax_original as mudanças ocorridas em
                // pax.codcidade
            });

            const paises = await api.get(`/pais/listforchange/${paxData.codpais}`);
            this.setState({ paises: paises.data });

            const tiposdocpessoal = await api.get(`/tipodocpessoal/listforchange/${paxData.codtipodocpessoal}`);
            this.setState({ tiposdocpessoal: tiposdocpessoal.data });

            const cargos = await api.get(`/cargo/listforchange/${paxData.codcargo}`);
            this.setState({ cargos: cargos.data });

            const nacionalidades = await api.get(`/nacionalidade/listforchange/${paxData.idnacionalidade}`);
            this.setState({ nacionalidades: nacionalidades.data });

            // Busca as cidades após os dados do pax terem sido definidos
            const cidades = await api.get(`/cidade/listforchange/${paxData.codcidade}`);
            // Encontre a cidade correspondente ao codcidade de pax
            let cidadeSelecionada = cidades.data.find(cidade => cidade.codcidade === paxData.codcidade);
            //console.log('cidade selecionada >> ' + cidadeSelecionada)

            if (cidadeSelecionada == undefined) {
                cidadeSelecionada = {};
            }
            console.log('o que está em cidadeSelecionada ao carregar: ' + cidadeSelecionada);
            this.setState({ selectedCity: cidadeSelecionada });


        } else if (id === 'novo') {
            this.setState({ isNewRecord: true });
            this.setState(prevState => ({
                pax: {
                    ...prevState.pax,
                    tipopax: 1,
                    recebesms: ASIM
                }
            }));
            const paises = await api.get(`/pais/active/true`);
            this.setState({ paises: paises.data });
            const tiposdocpessoal = await api.get(`/tipodocpessoal/active/true`);
            this.setState({ tiposdocpessoal: tiposdocpessoal.data });
            const cargos = await api.get(`/cargo/active/true`);
            this.setState({ cargos: cargos.data });
            const nacionalidades = await api.get(`/nacionalidade/active/true`);
            this.setState({ nacionalidades: nacionalidades.data });
            this.setState({ selectedCity: { codcidade: null, desccidade: '', sigla: '' } });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            pax: {
                ...prevState.pax,
                [name]: value
            }
        }));
    };

    handleSave = () => {
        console.log('Dados salvos:', this.state);
        if (this.props.onSave) {
            this.props.onSave();
        }
    };

    handleCancel = () => {
        this.props.onClose();
    };

    // Campos para tratamento de exceção no OnChange
    nonUpperCaseFields = ['email'];
    integerFields = ['idade', 'codfamilia'];

    OnChange = (event) => {
        const { name, type, value, checked } = event.target;
        let adjustedValue = value;

        console.log(`name: ${name} > ${type} > ${value}`);

        switch (type) {
            case 'checkbox':
                if (name === 'foneint' || name === 'recebesms') {
                    adjustedValue = checked ? ASIM : ANAO;
                }
                break;

            case 'select-one':
                adjustedValue = name === 'sexo' ? value : Number(value);
                break;

            case 'date':
                adjustedValue = value;
                break;

            default:
                // Verifica se o campo deve ser convertido para minúsculas
                if (this.nonUpperCaseFields.includes(name)) {
                    adjustedValue = value.toLowerCase();
                } else {
                    adjustedValue = value.toUpperCase();
                }

                // Se for um campo numérico (definido em integerFields) e o valor estiver vazio, atribui null
                if (this.integerFields.includes(name)) {
                    if (value === '') {
                        adjustedValue = null;
                    } else {
                        adjustedValue = parseInt(value, 10);
                        // Verifica se a conversão foi válida
                        if (isNaN(adjustedValue)) {
                            adjustedValue = null;
                        }
                    }
                }
                break;
        }

        console.log(`Novo valor: ${adjustedValue}`);

        this.setState(prevState => ({
            pax: {
                ...prevState.pax,
                [name]: adjustedValue
            },
            errors: {
                ...prevState.errors,
                [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
            }
        }));
    }

    onClickSave = () => {
        if (this.state.isNewRecord) {
            this.inserePax();
        } else {
            this.atualizaPax();
        }
    }

    validationRequiredFields = () => {
        const { pax } = this.state;
        const { cpfError } = this.state;
        const { schema } = this.state;
        const codpais = getConfigField('codpais');
        let errors = {};
        const nomePaxError = validateField('nomepax', pax.nomepax, schema);
        if (nomePaxError) {
            errors.nomepax = nomePaxError;
        }
        const foneError = validateField('fone', pax.fone, schema);
        if (foneError) {
            errors.fone = foneError;
        }
        const rgError = validateField('rg', pax.rg, schema);
        if (rgError) {
            errors.rg = rgError;
        }
        const idadeError = validateField('idade', pax.idade, schema);
        if (idadeError) {
            errors.idade = idadeError;
        }
        const codfamiliaError = validateField('codfamilia', pax.codfamilia, schema);
        if (codfamiliaError) {
            errors.codfamilia = codfamiliaError;
        }
        const cpfpaxError = validateField('cpfpax', pax.cpfpax, schema);
        if (cpfpaxError) {
            errors.cpfpax = cpfpaxError;
        }
        const emailError = validateField('email', pax.email, schema);
        if (emailError) {
            errors.email = emailError;
        }
        const orgaoemissordocError = validateField('orgaoemissordoc', pax.orgaoemissordoc, schema);
        if (orgaoemissordocError) {
            errors.orgaoemissordoc = orgaoemissordocError;
        }

        else {
            if ((cpfError) && (codpais == tPais.APAISBRASIL)) {
                let cpf = {
                    field: {
                        presence: { allowEmpty: false, message: 'CPF inválido!' },
                    }
                }
                errors.cpfpax = cpf;
                this.handleOpenAlert(errors.cpfpax.field.presence.message);
                return errors;
            }
        }

        return errors;
    };


    inserePax = async () => {
        const { pax } = this.state;
        console.log('irá inserir um cadastro..');
        console.log('o que está em props.codOS:' + this.props.codOS);
        try {
            if (!(this.props.codOS === undefined)) {
                console.log('vai chamar api para salvar pax na inserção de novo')
                const response = await api.post(`/pax`, pax);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                console.log('identificou que é um novo registro não adicionará agora..')
                this.handleCancel();
                this.props.onSave(this.state.pax);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    atualizaPax = async () => {
        const { pax, pax_original } = this.state;
        console.log('vai atualizar o pax: ' + JSON.stringify(pax));
        console.log('o pax original tinha: ' + JSON.stringify(pax_original));

        const differences = diff(pax_original, pax);

        console.log(' o que está em diferences: ' + JSON.stringify(differences));


        if (!differences) {
            this.handleCancel();
            return;
        }

        const paxAlterado = {};
        differences.forEach(d => {
            if (d.kind === 'E' || d.kind === 'N') {
                paxAlterado[d.path.join('.')] = d.rhs;
            }
        });

        try {
            if (!(this.props.codOS === undefined)) {
                const response = await api.put(`/pax/${pax.idpax}`, paxAlterado);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.pax);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    handleSubmit = () => {
        let errors = this.validationRequiredFields();
        console.log('the errors..' + JSON.stringify(errors))
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.onClickSave();
        }
    };

    handleOpenAlert = (message) => {
        this.setState({ openAlert: true, messageAlert: message });
    };

    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    };

    // Função que será passada como prop para o CitySearch
    handleCitySelection = (city) => {
        console.log('handleCitySelection:' + city);
        let { pax } = this.state;
        pax.codcidade = city.codcidade;
        console.log('o codigo da nova cidade: ' + pax.codcidade);
        //this.setState({ pax : pax });
        this.setState({ selectedCity: city, pax: pax });
    };

    /*loadConfigs = () => {
        const configs = loadConfigurations();
        if (configs) {
            this.setState({ configurations: configs, isLoadingConfig: false });
        } else {
            // Opcional: lidar com o caso em que as configurações não estão disponíveis
            this.setState({ isLoadingConfig: false });
        }
    }; */

    /* verifica validade CPF */
    handleCPFBlur = () => {
        const { cpfpax } = this.state.pax;

        console.log('cpf informado: ' + cpfpax)
        if (!(cpfpax == '___.___.___-__')) {
            console.log('o que está em cpfpax: ' + cpfpax)
            if (!validarCPF(cpfpax)) {
                this.setState({ cpfError: true });
            } else {
                this.setState({ cpfError: false });
            }
        } else {
            this.setState({ cpfError: false });
        }

    }
    render() {
        const { pax, cpfError } = this.state;
        const { isModal, classes, pais } = this.props;
        const { paises } = this.state;
        const { errors } = this.state;
        const { tiposdocpessoal } = this.state;
        const { cargos } = this.state;
        const { nacionalidades } = this.state;
        const { selectedCity } = this.state;
        //const { configurations, isLoadingConfig } = this.state;
        // dados da empresa
        const empresa = loadCompany();

        // campos configuracoes
        const codpais = getConfigField('codpais');
        const exibecampodatanascpax = (getConfigField('exibecampodatanascpax') == ASIM);
        const exibecampoidadepax = (getConfigField('exibecampoidadepax') == ASIM);
        const exibecampopaispax = (getConfigField('exibecampopaispax') == ASIM);
        const exibecampotipodocpessoal = (getConfigField('exibecampotipodocpessoal') == ASIM);
        const exibecamporgpax = (getConfigField('exibecamporgpax') == ASIM);
        const exibecampofamiliapax = (getConfigField('exibecampofamiliapax') == ASIM);
        const exibecampocpfpax = (getConfigField('exibecampocpfpax') == ASIM);
        const exibecampofonepax = (getConfigField('exibecampofonepax') == ASIM);
        const exibecampocargopax = (getConfigField('exibecampocargopax') == ASIM);
        const exibecampocidadepax = (getConfigField('exibecampocidadepax') == ASIM);
        const exibecamposexopax = (getConfigField('exibecamposexopax') == ASIM);
        const exibecampoemailpax = (getConfigField('exibecampoemailpax') == ASIM);
        const exibecamponacionalidepax = (getConfigField('exibecamponacionalidepax') == ASIM);
        const exibeorgaoemissdocpax = (getConfigField('exibeorgaoemissdocpax') == ASIM);
        const temmascarapersonfone = (getConfigField('temmascarapersonfone') == ASIM);
        const mascarapersonfone = (getConfigField('mascarapersonfone'));

        // configurações CRM
        const enviasmscomservico = (getConfigField('enviasmscomservico', 'cfg_crm') == ASIM);
        //console.log('exibe campo cidade pax: ' + exibecampocidadepax);

        return (
            <Dialog open={isModal} onClose={this.handleCancel}>
                <DialogContent className={classes.modalContent}>
                    <TextField
                        label="Nome Pax"
                        name="nomepax"
                        value={pax.nomepax || ''}
                        onChange={this.OnChange}
                        fullWidth
                        margin="normal"
                        style={{ width: '100%' }}
                        helperText={errors.nomepax || ''}
                        error={!!errors.nomepax}
                    />
                    <Box width="100%">

                        <InputLabel
                            htmlFor="outlined-age-native-simple"
                            shrink={true}
                            style={{ padding: '0 4px' }}
                        >
                            Tipo de Pax
                        </InputLabel>
                        <Select
                            inputProps={{
                                id: 'outlined-age-native-simple',
                                name: 'tipopax'
                            }}
                            label="tipopax"
                            native
                            onChange={this.OnChange}
                            value={pax.tipopax || ''}
                        >
                            <option key={APAX_ADT} value={APAX_ADT}>ADT</option>
                            <option key={APAX_CHD} value={APAX_CHD}>CHD</option>
                            <option key={APAX_INF} value={APAX_INF}>INF</option>
                            <option key={APAX_SEN} value={APAX_SEN}>SEN</option>
                        </Select>

                        <InputLabel
                            htmlFor="outlined-age-native-simple"
                            shrink={true}
                            style={{ padding: '0 4px' }}
                        >
                            Categoria
                        </InputLabel>
                        <Select
                            inputProps={{
                                id: 'outlined-age-native-simple',
                                name: 'categoria'
                            }}
                            label="categoria"
                            native
                            onChange={this.OnChange}
                            value={pax.categoria || ''}
                        >
                            <option key={ACATEGORIA_PAX_PAGANTE} value={ACATEGORIA_PAX_PAGANTE}>PAGANTE</option>
                            <option key={ACATEGORIA_PAX_MEIAENTRADA} value={ACATEGORIA_PAX_MEIAENTRADA}>MEIA-ENTRADA</option>
                            <option key={ACATEGORIA_PAX_CORTESIA} value={ACATEGORIA_PAX_CORTESIA}>FREE</option>
                        </Select>

                        {exibecampodatanascpax && (
                            <TextField
                                className={classes.textField}
                                label="Data Nasc."
                                name="datanasc"
                                type="date"
                                value={pax.datanasc}
                                onChange={this.OnChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    </Box>
                    {exibecampoidadepax && (
                        <TextField
                            label="Idade"
                            name="idade"
                            value={pax.idade || ''}
                            onChange={this.OnChange}
                            fullWidth
                            margin="normal"
                            type="number"
                            helperText={errors.idade}
                            error={!!errors.idade}
                        />
                    )}

                    {exibecampopaispax && (

                        <FormControl
                            //  fullWidth
                            className={classes.textField}
                            //  variant="outlined"
                            disabled={this.state.isView}
                            style={{ marginRight: "1rem" }}
                        >

                            <ComboPadrao
                                name={'codpais'}
                                label={'País'}
                                value={pax.codpais}
                                onChange={this.OnChange}
                                fieldId={'codpais'}
                                listItems={paises}
                                fieldDescription={'nomepais'}
                                helperText={errors.codpais}
                            />

                        </FormControl>
                    )}
                    {exibecampotipodocpessoal && (
                        <FormControl
                            //  fullWidth
                            className={classes.textField}
                            //  variant="outlined"
                            disabled={this.state.isView}
                            style={{ marginRight: "1rem" }}
                        >

                            <ComboPadrao
                                name={'codtipodocpessoal'}
                                label={'Tipo Doc.'}
                                value={pax.codtipodocpessoal}
                                onChange={this.OnChange}
                                fieldId={'codtipodocpessoal'}
                                listItems={tiposdocpessoal}
                                fieldDescription={'desctipodocpessoal'}
                                helperText={errors.codtipodocpessoal}
                            />

                        </FormControl>
                    )}

                    {exibecamporgpax && (

                        <TextField
                            label="Documento"
                            name="rg"
                            value={pax.rg || ""}
                            onChange={this.OnChange}
                            fullWidth
                            margin="normal"
                            helperText={errors.rg || ''}
                            error={!!errors.rg}
                        />
                    )}

                    {exibecampofamiliapax && (

                        <TextField
                            label="Família"
                            name="codfamilia"
                            value={pax.codfamilia || ''}
                            onChange={this.OnChange}
                            fullWidth
                            margin="normal"
                            type="number"
                            helperText={errors.codfamilia}
                            error={!!errors.codfamilia}
                        />



                    )}

                    {/* Aplicar a máscara de CPF somente quando o país for Brasil */}

                    {exibecampocpfpax && (
                        (codpais == tPais.APAISBRASIL) ? (
                            <InputMask
                                mask="999.999.999-99"
                                value={pax.cpfpax || ""}
                                onChange={this.OnChange}
                                onBlur={this.handleCPFBlur}

                            >
                                {(inputProps) => (
                                    <TextField
                                        {...inputProps}
                                        label="CPF"
                                        name="cpfpax"
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        error={cpfError || !!errors.cpfpax}
                                        helperText={cpfError || !!errors.cpfpax ? "CPF inválido" : ""}
                                    //helperText={errors.nomepax || ''}
                                    //error={!!errors.cpfpax}

                                    />
                                )}
                            </InputMask>
                        ) : (
                            <TextField
                                label="CPF"
                                name="cpfpax"
                                value={pax.cpfpax || ""}
                                onChange={this.OnChange}
                                fullWidth
                                margin="normal"
                                type="text"
                                helperText={errors.cpfpax || ''}
                                error={!!errors.cpfpax}
                            />
                        )
                    )}

                    {exibecampofonepax && (
                        <Box display="flex" alignItems="center">
                            {temmascarapersonfone ? (
                                mascarapersonfone ? (
                                    // Se `mascarapersonfone` tiver um valor, aplica a máscara contida nele
                                    <InputMask
                                        mask={mascarapersonfone} // Usa a máscara personalizada
                                        value={pax.fone || ""}
                                        onChange={this.OnChange}
                                    >
                                        {(inputProps) => (
                                            <TextField
                                                {...inputProps}
                                                label="Fone"
                                                name="fone"
                                                fullWidth
                                                margin="normal"
                                                type="text"
                                                error={!!errors.fone}
                                                helperText={errors.fone || ""}
                                            />
                                        )}
                                    </InputMask>
                                ) : (
                                    // Se `temmascarapersonfone` for `true`, mas `mascarapersonfone` estiver vazio, exibe sem máscara
                                    <TextField
                                        label="Fone"
                                        name="fone"
                                        value={pax.fone || ""}
                                        onChange={this.OnChange}
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        error={!!errors.fone}
                                        helperText={errors.fone || ""}
                                    />
                                )
                            ) : (
                                // Se `temmascarapersonfone` for `false`, aplica a máscara padrão
                                <InputMask
                                    mask="(99) 9999-9999" // Máscara padrão
                                    value={pax.fone || ""}
                                    onChange={this.OnChange}
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            label="Fone"
                                            name="fone"
                                            fullWidth
                                            margin="normal"
                                            type="text"
                                            error={!!errors.fone}
                                            helperText={errors.fone || ""}
                                        />
                                    )}
                                </InputMask>
                            )}

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pax.foneint === ASIM}
                                        onChange={this.OnChange}
                                        name="foneint"
                                        color="primary"
                                        value={pax.foneint}
                                    />
                                }
                                label="Internacional"
                                style={{ marginLeft: "10px" }}
                            />
                        </Box>
                    )}

                    {empresa.modulocrm && exibecampofonepax && enviasmscomservico && (
                        <Box display="flex" alignItems="center">

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pax.recebesms == ASIM}
                                        onChange={this.OnChange}
                                        name="recebesms"
                                        color="primary"
                                        value={pax.recebesms || 'S'}
                                    />
                                }
                                label="Recebe SMS"
                                style={{ marginLeft: '10px' }}
                            />
                        </Box>

                    )}

                    {exibecampocargopax && (

                        <FormControl
                            //  fullWidth
                            className={classes.textField}
                            //  variant="outlined"
                            disabled={this.state.isView}
                            style={{ marginRight: "1rem" }}
                        >

                            <ComboPadrao
                                name={'codcargo'}
                                label={'Cargo'}
                                value={pax.codcargo}
                                onChange={this.OnChange}
                                fieldId={'codcargo'}
                                listItems={cargos}
                                fieldDescription={'cargo'}
                                helperText={errors.codcargo}
                            />

                        </FormControl>

                    )}

                    {exibecampocidadepax && (
                        <div>
                            {/* garantindo a renderizacao */}
                            {selectedCity && (
                                <>
                                    {/* Renderiza o CitySearch passando a função handleCitySelection como prop */}
                                    <SearchCidade
                                        defaultCity={selectedCity || ''}
                                        onCitySelect={this.handleCitySelection} />
                                    <div>
                                    </div>
                                </>
                            )}
                        </div>

                    )}


                    {exibecamposexopax && (
                        <>
                            <InputLabel
                                htmlFor="outlined-sexo-native-simple"
                                shrink={true}
                                style={{ padding: '0 4px' }}
                            >
                                Sexo
                            </InputLabel>
                            <Select
                                inputProps={{
                                    id: 'outlined-sexo-native-simple',
                                    name: 'sexo'
                                }}
                                label="Sexo"
                                native
                                onChange={this.OnChange}
                                value={pax.sexo || ''}
                            >
                                <option key={ASEXO_MASCULINO} value={ASEXO_MASCULINO}>Masculino</option>
                                <option key={ASEXO_FEMININO} value={ASEXO_FEMININO}>Feminino</option>
                            </Select>
                        </>
                    )}

                    {exibecampoemailpax && (

                        <TextField
                            label="E-mail"
                            name="email"
                            value={pax.email || ""}
                            onChange={this.OnChange}
                            fullWidth
                            margin="normal"
                            type="text"
                            helperText={errors.email || ''}
                            error={!!errors.email}

                        />
                    )}

                    {exibecamponacionalidepax && (

                        <FormControl
                            //  fullWidth
                            className={classes.textField}
                            //  variant="outlined"
                            disabled={this.state.isView}
                            style={{ marginRight: "1rem" }}
                        >


                            <ComboPadrao
                                name={'idnacionalidade'}
                                label={'Nacionalidade'}
                                value={pax.idnacionalidade}
                                onChange={this.OnChange}
                                fieldId={'idnacionalidade'}
                                listItems={nacionalidades}
                                fieldDescription={'descnacionalidade'}
                                helperText={errors.idnacionalidade}
                            />

                        </FormControl>
                    )}

                    {exibeorgaoemissdocpax && (

                        <TextField
                            label="Órgão Emissor Doc."
                            name="orgaoemissordoc"
                            value={pax.orgaoemissordoc || ""}
                            onChange={this.OnChange}
                            fullWidth
                            margin="normal"
                            helperText={errors.orgaoemissordoc || ''}
                            error={!!errors.orgaoemissordoc}


                        />

                    )}

                    <br /><br />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                        style={{ marginRight: '10px' }}
                    >
                        Salvar
                    </Button>

                    {isModal && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleCancel}
                        >
                            Cancelar
                        </Button>
                    )}

                    <div>
                        <AlertInformation
                            open={this.state.openAlert}
                            handleClose={this.handleCloseAlert}
                            message={this.state.messageAlert}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(PaxForm);
